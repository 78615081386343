import React, { useState } from 'react';
import classNames from 'classnames';
import leftImg from '../../assets/drivemateSection/left.png';
import rightImage from '../../assets/drivemateSection/rightImg.png';
import driveGO from '../../assets/logos/GoLogo.png';
import CheckIcon from '../../assets/drivemateSection/check-circle-solid.svg';
import profileImg from '../../assets/drivemateSection/profileImg.png';
import videoIcon from '../../assets/drivemateSection/videoIcon.svg';
import imageMobile from '../../assets/drivemateSection/imageMobile.png';
import { FormattedMessage } from 'react-intl';
import { Modal, NamedLink, PrimaryButton } from '../../components';
import css from './LandingPageNew.css';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import { sendGAEvent } from '../../util/googleAnalytics';

const DriveMateSection = ({
  onManageDisableScrolling,
  onPushGTMSeeVideoEvent,
  onVideoModalOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
    onVideoModalOpen(true);
  };
  return (
    <div className={css.landingContainer}>
      <div className={classNames(css.landingCol)}>
        <div className={css.sectionLeftContent}>
          <img src={leftImg} alt="Long term car rental for your holiday plans | Drive Mate" />
        </div>
        <div className={css.sectionRightContent}>
          <h2>What’s Drive mate?</h2>
          <span>We’re all mates here. Don’t grab a ride. Get a car!</span>
          <div className={css.paraContent}>
            <p>
              Drive mate allows customers to borrow cars from neighbours offering up their vehicles
              on our secure, all-in-one rental app.
            </p>
            <p>
              Forget ride-share. Get your own wheels sorted and secured for as little as $4/hour or $30/day
              without the frustrations of typical car rental rigmarole
            </p>
            <p>
              Simply check out our top rented vehicles in your neighbourhood, create an account
              using the Drive mate app, then go pay your neighbour a visit and pick up the car!
            </p>
          </div>
        </div>
      </div>

      <div className={css.whyWhirl}>
        <div className={css.mobileImage}>
          <img src={imageMobile} alt="" />
        </div>
        <div className={css.leftContent}>
          <h3>Why give Drive mate a whirl?</h3>
          <p>
            Maybe you want to simplify your transportation needs without owning a vehicle? Perhaps
            you’re after a more convenient solution to your car rental woes?
          </p>
          <div className={css.contentBox}>
            <div className={css.list}>
              <img src={CheckIcon} alt="" />
              <p>Better options, minus the hassle</p>
            </div>
            <div className={css.list}>
              <img src={CheckIcon} alt="" />
              <p>Being fully insured</p>
            </div>
            <div className={css.list}>
              <img src={CheckIcon} alt="" />
              <p>Having what you need available – Always!</p>
            </div>
            <div className={css.list}>
              <img src={CheckIcon} alt="" />
              <p>Ripper savings of up to 40% compared to regular rental services</p>
            </div>
          </div>
          <div className={css.video} onClick={handleOpenVideo} style={{ cursor: 'pointer' }}>
            <div className={css.profileTitle}>
              <img src={profileImg} alt="Daily short term rental cars available | Drive Mate" />
              <p>Watch video to learn more</p>
            </div>
            <img src={videoIcon} alt="" />
          </div>
        </div>
      </div>

      <div className={classNames(css.landingCol, css.landingGo)}>
        <div className={css.sectionRightContent}>
          <div className={css.logo}>
            <img src={driveGO} />
          </div>
          <h2>Book, Unlock and Go!</h2>
          <div className={css.paraContent}>
            <p>
              Drive mate Go™ is an innovative Australian industry first advancing the car sharing
              concept into smarter, safer territory!
            </p>
            <p>
              We’ve introduced remote tracking and immobilisation functionality and utilise
              telemetry data to ensure Drive mate remains one of the most convenient and safe car
              sharing platforms.
            </p>
          </div>
          <NamedLink name="LandingPageGo" className={css.learnButton}>
            <PrimaryButton type="button">
              <FormattedMessage id="LandingSectionBanner.LearnMoreMessage" />
            </PrimaryButton>
          </NamedLink>
        </div>
        <div className={css.sectionLeftContent}>
          <img src={rightImage} alt="Best car sharing. Book, Unlock and Go with Drive Mate" />
        </div>
      </div>
      <Modal
        id="videoLandingpage"
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalCloseButton}
        contentClassName={css.contentModal}
        isOpen={isOpen}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
          onVideoModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalVideo isOpen={isOpen} />
      </Modal>
    </div>
  );
};

const ModalVideo = ({ isOpen }) => {
  return (
    <div className={css.videoContentWrapper}>
      {isOpen && (
        <iframe
          id="videoIframe"
          className={css.iframeVideo}
          allowFullScreen={true}
          src="https://www.youtube.com/embed/-fg9W0MZYuo"
        ></iframe>
      )}
    </div>
  );
};

export default DriveMateSection;
