import React from 'react';
import styles from './LandingPageTestimonials.module.css';
import noahHaris from '../../assets/testimonialImages/Noah Harris.jpg'
import oliviaJohnson from '../../assets/testimonialImages/Olivia Johnson.jpg'
import jamesA from '../../assets/testimonialImages/James A.jpg'
import miaSmith from '../../assets/testimonialImages/Mia Smith - 1.jpg'
import charlotteWalker from '../../assets/testimonialImages/Charlotte Walker.jpg'
import michaelTan from '../../assets/testimonialImages/Michael Tan.jpg'
import emilyTaylor from '../../assets/testimonialImages/Emily  Taylor.jpg'
import natashaLim from '../../assets/testimonialImages/Natasha Lim.jpg'

const LandingPageTestimonials = () => {

  const testimonialCollection = [
    {
      customerName: 'Noah Harris',
      customerImg: noahHaris,
      rating: 5,
      review: "So, I tried this for the first time. I gotta admit, I was apprehensive at the start. But the folks on the chat were lightning fast with their help, Great experience will use again.",
    },
    {
      customerName: 'Olivia Johnson',
      customerImg: oliviaJohnson,
      rating: 5,
      review: "Highly recommend giving this app a shot, folks! It's a breeze to use. 👍👍",
    },
    {
      customerName: 'James A',
      customerImg: jamesA,
      rating: 5,
      review: "Great platform and great host, Charles. Super helpful. Huge shoutout and thanks for letting me borrow your wheels! ❤️❤️",
    },
    {
      customerName: 'Mia Smith',
      customerImg: miaSmith,
      rating: 5,
      review: "Easy platform for renting cars! Will recommend it, thanks.",
    },
    {
      customerName: 'Charlotte Walker',
      customerImg: charlotteWalker,
      rating: 5,
      review: "Super efficient, super reliable.",
    },
    {
      customerName: 'Michael Tan',
      customerImg: michaelTan,
      rating: 5,
      review: "Quick support,  Great platform - will recommend to my friends for sure",
    },
    {
      customerName: 'Emily  Taylor',
      customerImg: emilyTaylor,
      rating: 5,
      review: "Drive mate is really efficient. It's easy to rent a car even at the last minute.",
    },
    {
      customerName: 'Natasha Lim',
      customerImg: natashaLim,
      rating: 5,
      review: "Drive is my go-to for weekend car rentals! Their app makes booking super easy, and their customer service is always top-notch. Highly recommended!",
    }

  ];

  const testimonialList = testimonialCollection.map((ele, index) => (
    <div key={index} className={styles.testimonialBox} >
      <div className={styles.customerImage}>
        <img src={ele.customerImg} alt="customer image" />
      </div>
      <div className={styles.testimonialData}>
        <p>{ele.review}</p>
        <p>{ele.customerName}</p>
        <p>{'⭐'.repeat(ele.rating)}</p>
      </div>
    </div>
  ))

  return (
    <section className={styles.slider}>
      <div className={styles.testimonialSlider}>
        <div>
          {testimonialList}
        </div>
        <div>
          {testimonialList}
        </div>
      </div>
    </section>
  );
};

export default LandingPageTestimonials;
