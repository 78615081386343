import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React, { useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  Explanation,
  FieldCurrencyInput,
  FieldSelectDay,
  FieldTextInput,
  FieldToggleSwitch,
  Form,
} from '../../components';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import css from './EditListingPricingForm.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = ({ initialValues, ...props }) => {
  const currentValues = useRef({});
  const submitedValues = useRef({});
  const [mounted, setMounted] = useState(false);

  return (
    <FinalForm
      initialValues={{ ...initialValues, ...currentValues.current }}
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          submitButtonId,
        } = formRenderProps;
        if (!mounted) {
          if (values.week) {
            const getDiscount = values.week ? values.week.toString() : 0;
            const getLast = getDiscount.substr(getDiscount.length - 1);
            if (getLast !== '%') {
              form.change('week', getDiscount + '%');
            }
            setMounted(true);
          }
        }

        const showPickFields = values.isPeakPriceEnabled;
        const showRentalPriceFields = values.longTermRental;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const translationKey = isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });
        const enablePeakPrice = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.enablePeakPrice',
            })}
          </React.Fragment>
        );

        const setPeakPriceHolidayExplanationInfo = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.setPeakPriceHolidayExplanationInfo',
            })}
          </React.Fragment>
        );

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        const peakPriceLabel = intl.formatMessage({
          id: 'EditListingPricingForm.peakPriceLabel',
        });

        const regularDaysLabel = (
          <React.Fragment>
            {intl.formatMessage(
              {
                id: 'EditListingPricingForm.peakPriceDaysSelection',
              },
              {
                limit: '(max 2)',
              }
            )}
          </React.Fragment>
        );

        const peakDaysLabel = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.peakDaysLabel',
            })}
            <Explanation text="You can choose to price particular days of the week at a different rental price." />
          </React.Fragment>
        );

        const weekLabel = intl.formatMessage({
          id: 'EditListingPricingForm.weekLabel',
        });

        const monthLabel = intl.formatMessage({
          id: 'EditListingPricingForm.monthLabel',
        });

        const weekPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.weekPlaceholder',
        });

        const monthPlaceholder = intl.formatMessage({
          id: 'EditListingPricingForm.monthPlaceholder',
        });

        const discountMinValueMessage = intl.formatMessage({
          id: 'EditListingPricingForm.discountMinValueMessage',
        });

        const discountMaxValueMessage = intl.formatMessage({
          id: 'EditListingPricingForm.discountMaxValueMessage',
        });

        const peakDaysAtMostSelectionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.peakDaysAtMostSelection',
        });

        console.log("peakDaysAtMostSelectionMessage", peakDaysAtMostSelectionMessage);
        const longTernRentalExplanationInfo = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.longTernRentalExplanationInfo',
            })}
          </React.Fragment>
        );

        // Listing Long term price field
        const longTermPriceLabel = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.longTermPriceLabel',
            })}
          </React.Fragment>
        );
        const longTermPricelLabelInfo = (
          <React.Fragment>
            {intl.formatMessage({
              id: 'EditListingPricingForm.longTermPricelLabelInfo',
            })}
          </React.Fragment>
        );


        const discountValidate = (field, values) => value => {
          let message = undefined;
          const { price, peakPrice } = values;
          if (values.price) {
            let totalPrice = 0;
            if (field === 'week') {
              totalPrice = (price.amount * 7 * (100 - parseInt(value))) / 100;
            } else if (field === 'month') {
              totalPrice = (price.amount * 30 * (100 - parseInt(value))) / 100;
            }
            if (totalPrice < 1)
              message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
          }
          if (values.peakPrice) {
            let totalPrice = 0;
            if (field === 'week') {
              totalPrice = (peakPrice.amount * 7 * (100 - parseInt(value))) / 100;
            } else if (field === 'month') {
              totalPrice = (peakPrice.amount * 30 * (100 - parseInt(value))) / 100;
            }
            if (totalPrice < 1)
              message = intl.formatMessage({ id: 'EditListingPricingForm.invalidDiscountValue' });
          }

          return message;
        };

        console.log("values.peakDays", values.peakDays)

        return (
          <Form
            onSubmit={e => {
              currentValues.current = values;
              submitedValues.current = values;
              handleSubmit(e);
            }}
            className={classes}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}

            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12, css.inputWrapper)}>
                <div className={css.customInputLabel}>{pricePerUnitMessage}</div>
                <div className={css.inputWithPrefix}>
                  <FieldCurrencyInput
                    id="price"
                    name="price"
                    prefix={<span className={css.inputPrefix}>$</span>}
                    className={classNames(css.priceInput, css.field, css.newInput)}
                    // label={pricePerUnitMessage}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={config.currencyConfig}
                    validate={priceValidators}
                  />
                </div>
              </div>
            </div>
            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="isPeakPriceEnabled">{enablePeakPrice}</label>
                    <Explanation
                      className={css.customClass}
                      text={setPeakPriceHolidayExplanationInfo}
                    />
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch name="isPeakPriceEnabled" id="isPeakPriceEnabled" />
                  </div>
                </div>
              </div>
            </div>

            {showPickFields && (
              <>
                {/* <div className={css.displayInlineContainer}>
                  <div className={classNames(css.column, css.column12)}>
                    <FieldSelectDay
                      id="regularDays"
                      className={classNames(css.field, css.newInput)}
                      name="regularDays"
                      initialsOnly={true}
                      label={regularDaysLabel}
                      customOnChange={(key, isSelected) => {
                        if (!isSelected) {
                          const { values } = form.getState();
                          const { peakDays = [] } = values;
                          setTimeout(() =>
                            form.change(
                              'peakDays',
                              peakDays.filter(day => day !== key)
                            )
                          );
                        } else {
                          const { values } = form.getState();
                          const { peakDays = [] } = values;
                          peakDays.push(key);
                          setTimeout(() => form.change('peakDays', peakDays));
                        }
                      }}
                    />
                  </div>
                </div> */}

                <div className={css.displayInlineContainer}>
                  <div className={classNames(css.column, css.column12, css.inputWrapper)}>
                    <div className={css.customInputLabel}>{peakPriceLabel}</div>
                    <div className={css.inputWithPrefix}>
                      <FieldCurrencyInput
                        id="peakPrice"
                        name="peakPrice"
                        prefix={<span className={css.inputPrefix}>$</span>}
                        className={classNames(css.priceInput, css.field, css.newInput)}
                        // label={peakPriceLabel}
                        placeholder={pricePlaceholderMessage}
                        currencyConfig={config.currencyConfig}
                        validate={(...priceData) => {
                          const { values } = form.getState();
                          const { peakDays = [], isPublicHolidayPeakDays = false } = values;
                          return peakDays.length > 0 || isPublicHolidayPeakDays
                            ? priceValidators(priceData[0])
                            : priceData[0]
                            ? minPriceRequired(priceData[0])
                            : undefined;
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className={css.displayInlineContainer}>
                  <div className={classNames(css.column, css.column12)}>
                    <FieldSelectDay
                        id="regularDays"
                        className={css.field}
                        name="regularDays"
                        label={regularDaysLabel}
                        initialsOnly={true}
                        peakDays={values.peakDays}
                        validate={(value, currentState) => {
                          const { peakDays = [] } = currentState || {};
                          if (peakDays && peakDays.length > 2) {
                            console.log("peakDays.length", peakDays.length > 2 && peakDaysAtMostSelectionMessage)
                            return peakDaysAtMostSelectionMessage;
                          }
                          return false;
                        }}
                        customOnChange={(key, isSelected) => {
                          if (!isSelected) {
                            const { values } = form.getState();
                            const { peakDays = [] } = values;
                            setTimeout(() =>
                              form.change(
                                'peakDays',
                                peakDays.filter(day => day !== key)
                              )
                            );
                          } else {
                            const { values } = form.getState();
                            const { peakDays = [] } = values;
                            peakDays.push(key);
                            setTimeout(() => form.change('peakDays', peakDays));
                          }
                        }}
                      />
                  </div>
                </div>
                <div className={css.displayInlineContainer}>
                  <div className={classNames(css.column, css.column12)}>
                    <div className={classNames(css.customToggleInput, css.field)}>
                      <div className={css.toggleLabels}>
                        <label htmlFor="isPublicHolidayPeakDays">Enable peak price for public holidays</label>
                        <Explanation text="Once activated, all the public holidays in your State/territory will automatically use the peak price set by you. You can turn off or on anytime." />
                      </div>
                      <div className={css.toggleInput}>
                        <FieldToggleSwitch name="isPublicHolidayPeakDays" id="isPublicHolidayPeakDays" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
             )}

            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12)}>
                <div id="discount" className={classNames(css.field, css.newInput)}>
                  <label htmlFor="discount">
                    <FormattedMessage id="EditListingPricingForm.discountTitle" />
                  </label>
                  <FieldTextInput
                    id="week"
                    name="week"
                    label={weekLabel}
                    placeholder={weekPlaceholder}
                    type="text"
                    step={1}
                    min={0}
                    max={100}
                    inline
                    className={classNames(css.field, css.newInput, css.inlineElements)}
                    validate={validators.composeValidators(
                      discountValidate('week', values),
                      validators.minValue(discountMinValueMessage, 0),
                      validators.maxValue(discountMaxValueMessage, 75)
                    )}
                  />
                  {/* <FieldTextInput
                    id="month"
                    name="month"
                    label={monthLabel}
                    placeholder={monthPlaceholder}
                    type="number"
                    step={1}
                    min={0}
                    max={100}
                    inline
                    parse={v => parseInt(v)}
                    validate={validators.composeValidators(
                      discountValidate('month'),
                      validators.minValue(discountMinValueMessage, 0),
                      validators.maxValue(discountMaxValueMessage, 100)
                    )}
                  /> */}
                </div>
              </div>
            </div>

            {/*Disabling long term rental*/}
            {/*<div className={css.displayInlineContainer}>*/}
            {/*  <div className={classNames(css.column, css.column12)}>*/}
            {/*    <div className={classNames(css.customToggleInput, css.longTermRentalWrapper, css.field)}>*/}
            {/*      <div className={css.toggleLabels}>*/}
            {/*        <label htmlFor="longTermRental">Enable Long Term Rental (Recommended)</label>*/}
            {/*        <Explanation text="Make your car available to be booked for long term (60+ days) at a fixed monthly price and ensure a constant monthly income." />*/}
            {/*      </div>*/}
            {/*      <div className={css.toggleInput}>*/}
            {/*        <FieldToggleSwitch name="longTermRental" id="longTermRental" />*/}
            {/*      </div>*/}
            {/*      <div className={css.infoText}>*/}
            {/*        <p>Allow guests to book your car for long term, i.e. greater than 2 months.</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  {showRentalPriceFields && (*/}
            {/*  <div className={classNames(css.column, css.column12)}>*/}
            {/*    <div className={css.customInputLabel}>{longTermPriceLabel}</div>*/}
            {/*    <div className={classNames(css.inputWithPrefix, css.monthlyPriceInput)}>*/}
            {/*      <FieldCurrencyInput*/}
            {/*        id="longTermPrice"*/}
            {/*        name="longTermPrice"*/}
            {/*        className={classNames(css.priceInput, css.field, css.newInput)}*/}
            {/*        prefix={<span className={css.inputPrefix}>$</span>}*/}
            {/*        // label={peakPriceLabel}*/}
            {/*        placeholder={pricePlaceholderMessage}*/}
            {/*        currencyConfig={config.currencyConfig}*/}
            {/*        validate={(...priceData) => {*/}
            {/*          const { values } = form.getState();*/}
            {/*          const { peakDays = [], isPublicHolidayPeakDays = false } = values;*/}
            {/*          return peakDays.length > 0 || isPublicHolidayPeakDays*/}
            {/*            ? priceValidators(...priceData)*/}
            {/*            : priceData[0]*/}
            {/*            ? minPriceRequired(priceData[0])*/}
            {/*            : undefined;*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    /!* <div className={css.infoText}>*/}
            {/*      <span className={css.infoIcon}>*/}
            {/*        <img src={infoCircle} alt="" />*/}
            {/*      </span>*/}
            {/*      <p>{longTermPricelLabelInfo}</p>*/}
            {/*    </div> *!/*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*</div>*/}
            {/* <div className={classNames(css.deliveryWrapper, css.field)}>
              <label htmlFor="delivery">
                <FormattedMessage id="EditListingPricingForm.delivery" />
              </label>
              <Explanation text="Some text." />
              <FieldToggleSwitch name="delivery" id="delivery" />
            </div> */}


            {/* <FormSpy
              subscription={{ values: true, active: true }}
              render={({ values, active }) => {
                console.log("values.longTermRental--->", values.longTermRental)
                if (values.longTermRental) {
                  return (
                    <FieldCurrencyInput
                      id="longTermPrice"
                      name="longTermPrice"
                      className={classNames(css.priceInput, css.field)}
                      label={
                        <div>
                          Monthly price in{' '}
                          <Explanation text="We recommend the Monthly price to be at least 10-15% discounted as compared to Daily price" />
                        </div>
                      }
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      validate={priceValidators}
                    />
                  );
                }
                return null;
              }}
              onChange={({ active }) => {
                const { active: previouslyActiveField } = formRenderProps;
                if (values.week) {
                  const getDiscount = values.week.toString();
                  const getLast = getDiscount.substr(getDiscount.length - 1);
                  if (active === 'week') {
                    if (getLast === '%') {
                      form.change('week', getDiscount.slice(0, -1));
                    }
                  } else if (previouslyActiveField === 'week') {
                    const getDiscount = values.week.toString();
                    const getLast = getDiscount.substr(getDiscount.length - 1);
                    console.log('previouslyActiveField', previouslyActiveField, active);
                    if (getLast !== '%') {
                      form.change('week', getDiscount + '%');
                    }
                  }
                }
              }}
            /> */}
            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}>
                  {/* <span className={css.descIcon}>
                    <img src={meterIcon} alt="" />
                    <img
                      src={meterNeedle}
                      alt=""
                      style={{ transform: meterDisplay }}
                      className={css.needle}
                    />
                  </span>
                  <p>
                    <strong>Good Demand:</strong> Based on your settings you are on your way to
                    attract good level of demand.
                  </p> */}
                </div>
                <div className={css.stickButton}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                    id={submitButtonId}
                  >
                    {/* {saveActionMsg} */}
                    Next
                  </Button>
                </div>
              </div>
            </div>

          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPricingFormComponent);
