import cookie from 'cookie_js';
import config from '../../config';

const getToken = () => {
  let token = null;
  Object.keys(cookie.all()).forEach(key => {
    if (/^st-.+-token$/.test(key)) {
      token = JSON.parse(cookie.get(key));
    }
  });
  if (token && token.scope !== 'user') {
    throw new Error('No access token. Please login.');
  }
  return token;
};

const createHeaders = (accessToken) => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };
};

const redirectToHome = () => {
  // window.location.href = config.canonicalRootURL;
};

/**
 * Make trusted post request.
 * @param {string} url
 * @param {object} body
 * @param {object} sdk
 * @returns {Promise<* | undefined>}
 */
export const appPost = (url, body, sdk) => {
  const makeRequest = () => {
    const accessToken = getToken().access_token;
    return window.fetch(`${config.apiUrl}/api/${url}`, {
      method: 'POST',
      headers: createHeaders(accessToken),
      body: JSON.stringify(body),
    });
  };

  const successHandler = async res => {
    if (res.status === 401 || res.status === 403) {
      redirectToHome();
    }
    const isJson = res.headers.get('content-type').includes('application/json');
    const data = isJson ? await res.json() : null;
    if (!res.ok) {
      throw data;
    }
    return data;
  };

  const errorHandler = error => {
    throw error;
  };

  return makeRequest()
    .then(async postRes => {
      if (postRes.status === 401 || postRes.status === 403) {
        return sdk.currentUser.show()
          .then(() => makeRequest().then(againRes => successHandler(againRes)))
          .catch(() => {
            throw postRes;
          })
        ;
      } else {
        return successHandler(postRes);
      }
    })
    .catch((error) => errorHandler(error))
  ;
};
