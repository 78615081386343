import classNames from 'classnames';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { bool, func, object, string } from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  InsurancePanelNew,
  NamedLink,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import {
  currentUserCanRequestToBooking,
  currentUserIdentityStatus,
  isChargeDeposit,
  listingIsInstantBooking,
} from '../../util/data';
import { sameDay, timestampToDate } from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { sendCheckoutSlackNotification } from '../../util/slackNotify';
import { bookingTypes, propTypes } from '../../util/types';
import css from './BookingTimeForm.css';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldEndDateAndTimeInput from './FieldEndDateAndTimeInput';
import FieldStartDateAndTimeInput from './FieldStartDateAndTimeInput';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PRESSED_BOOK_GUEST_ERROR } from '../../util/gtm/gtmConstants';
import AlertBox from '../../components/AlertBox/AlertBox';

export class BookingTimeFormComponent extends PureComponent {
  state = {
    loading: false,
    timeError: {},
    timeIsChanged: false,
    gtmErrorName: { name: '' },
    errorState: [],
    bookingData: null,
    showToast: false,
    showError: true
  };

  handleTimeRangeError = ({ values }) => {
    if (!isEqual(this.prevValues, values)) {
      const { listing, bookingConfig, updateBooking, transaction, localTimeZone } = this.props;
      const isInstantBooking = listingIsInstantBooking(listing);
      const timeZone =
        listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

      const isBusinessTime = (listing && listing.attributes && listing.attributes.metadata && listing.attributes.metadata.isBusinessTime) || false;
      const { bookingStartTime, bookingEndTime } = values;
      if (!bookingStartTime || !bookingEndTime) return;
      const currentTimeError = this.state.timeError;
      const now = moment();
      const momentStart = moment(timestampToDate(bookingStartTime), timeZone);
      const momentEnd = moment(timestampToDate(bookingEndTime), timeZone);

      const diffHours = momentEnd.diff(momentStart, 'hours', true);

      const diffDays = sameDay(timestampToDate(bookingStartTime), timestampToDate(bookingEndTime))
        ? momentEnd.diff(momentStart, 'days', true)
        : Math.ceil(momentEnd.diff(momentStart, 'days', true));


      if (updateBooking) {
        const bookedStart = transaction && transaction.booking && transaction.booking.attributes.displayStart ? moment(transaction.booking.attributes.displayStart, localTimeZone) : null;
        const bookedEnd = transaction && transaction.booking && transaction.booking.attributes.displayEnd ? moment(transaction.booking.attributes.displayEnd, localTimeZone) : null;
        const diffBookedHours = bookedEnd.diff(bookedStart, 'hours', true);

        currentTimeError[
          `Updated time should be longer then booked`
          ] = diffHours <= diffBookedHours;
      }

      const bookingStartHour = momentStart
        .clone()
        .tz(timeZone)
        .diff(
          momentStart
            .clone()
            .tz(timeZone)
            .startOf('day'),
          'hours',
          true,
        );

      const bookingEndHour = momentEnd
        .clone()
        .tz(timeZone)
        .diff(
          momentEnd
            .clone()
            .tz(timeZone)
            .startOf('day'),
          'hours',
          true,
        );

      if (isBusinessTime) {
        currentTimeError[
          `This listing can only be picked up or dropped off between 7.30AM & 5.30PM. Please update the timings and try again.`
          ] = (bookingStartHour >= 17.5 || bookingStartHour <= 7.5);

        currentTimeError[
          `This listing can only be picked up or dropped off between 7.30AM & 5.30PM. Please update the timings and try again.`
          ] = (bookingEndHour >= 17.5 || bookingEndHour <= 7.5);
      }

      if (isInstantBooking && !this.props.isLongTermBooking) {
        const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
        currentTimeError[
          `This car must be booked at least ${bookingConfig.paddingHours ||
          4} hours prior to the trip start time`
          ] =
          hoursToNow <
          (typeof bookingConfig.paddingHours === 'undefined' ? 4 : parseInt(bookingConfig.paddingHours));

        console.log('Booking start hours', bookingStartHour, timeZone, localTimeZone);

        currentTimeError[
          `Instant booking cars can not be picked up from 12am to 7am (car's owner timezone)`
          ] = ((bookingStartHour >= 0 && bookingStartHour <= 7));


        console.log('Booking end hours', bookingEndHour, timeZone, localTimeZone);
        currentTimeError[
          `Instant booking cars can not be dropped off from 12am to 7am (car's owner timezone)`
          ] = ((bookingEndHour >= 0 && bookingEndHour <= 7));


      } else {
        const hoursToNow = Math.abs(now.diff(momentStart, 'hours', true));
        currentTimeError[
          `This car must be booked at least ${bookingConfig.paddingHours ||
          2} hours prior to the trip start time`
          ] =
          hoursToNow <
          (typeof bookingConfig.paddingHours === 'undefined' ? 2 : parseInt(bookingConfig.paddingHours));
      }

      if (!updateBooking) {
        currentTimeError['Pickup time must be after the current time'] = momentStart.isSameOrBefore(
          now,
        );
      }
      currentTimeError[
        `This car can only be booked for a minimum of ${listing.attributes.publicData.minimumDailyDuration} days `
        ] = (listing.attributes.publicData.minimumDailyDuration !== 1 && parseInt(diffDays) > 1 && parseInt(diffDays) < listing.attributes.publicData.minimumDailyDuration);

      if (bookingConfig.type === bookingTypes.HOURLY) {
        if (diffHours < bookingConfig.minimum)
          currentTimeError[
            `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
            ] = true;
        else
          currentTimeError[
            `Invalid duration time (Equal/longer than ${bookingConfig.minimum} hours)`
            ] = false;
      } else {
        if (diffDays < bookingConfig.minimum) {
          currentTimeError[
            `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
            ] = true;
        } else {
          currentTimeError[
            `Invalid duration time (Equal/longer than ${bookingConfig.minimum} days)`
            ] = false;
        }
      }

      currentTimeError[`Booking start must be before booking end`] =
        // bookingStartTime > bookingEndTime;
        values.bookingStartDate.date.getTime() > values.bookingEndDate.date.getTime()

      Object.keys(currentTimeError).forEach(key => {
        if (currentTimeError[key] === false) {
          delete currentTimeError[key];
        }
      });

      this.prevValues = values;

      this.setState(prev => ({
        ...prev,
        timeError: currentTimeError,
      }));
    }
  };

  beyond6Months = day => {
    const limit = moment().add(6, 'months');
    return !limit.isSameOrAfter(day);
  };

  getErrorEventFired = (error = {}, errorType = '') => {
    const { listing } = this.props;
    const { gtmErrorName, errorState } = this.state;
    if (errorType === 'estimateError') {
      if (error) {
        if (error.status === 411) {
          gtmErrorName.name = 'You already have an accepted booking for the dates and times above.';
        } else if (error.apiErrors && error.apiErrors[0] && error.apiErrors[0].code && error.apiErrors[0].code === 'transaction-booking-time-not-available' || error.status === 410) {
          gtmErrorName.name = 'Some other user have a reservation already made for some part of your trip dates. Please select other dates and try again.';
        } else if (error.status === 409) {
          gtmErrorName.name = 'The requested booking time is not available';
        } else if (error.status === 412) {
          gtmErrorName.name = 'Some other user have a reservation already made for some part of your modified trip dates. Please select other dates and try again.';
        } else if (error.status === 413) {
          gtmErrorName.name = 'Since your profile is not verified yet, you need to book a trip at least 2 hours prior to the trip start time.';
        } else if (error.apiErrors && error.apiErrors[0] && error.apiErrors[0].details === 'positive-or-zero-payout?') {
          gtmErrorName.name = 'Only bigger amount of hours alowed.';
        } else {
          gtmErrorName.name = 'Something went wrong. Please refresh the page1111111.';
        }
      }
    }
    if (errorType === 'timeError') {
      gtmErrorName.name = Object.keys(this.state.timeError).join('. \n');
    }
    if (gtmErrorName.name && !errorState.includes(gtmErrorName.name)) {
      errorState.push(gtmErrorName.name);
      initiateEventFromListing({
        props: this.props,
        listing,
        pageName: 'ListingPage',
        event: EVENT_SEARCH_PRESSED_BOOK_GUEST_ERROR,
        bookingError: gtmErrorName.name,
        isHost: false,
      });
      this.setState({ errorState, gtmErrorName });
    }
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      timeSlots,
      currentUser,
      initialDate,
      initialValues: rawInitialValues,
      voucherCodeData,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id='BookingTimeForm.listingPriceMissing' />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id='BookingTimeForm.listingCurrencyInvalid' />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        rawInitialValues={rawInitialValues}
        currentUser={currentUser}
        unitPrice={unitPrice}
        onSubmit={values => {
          const { discountChoice, ...rest } = values;
          if(discountChoice === "credits" && !currentUser) {
            console.log("discountChoice", discountChoice);
            this.setState({
              showToast: true,
              showError: false
            })
          }
          if (!discountChoice || discountChoice !== 'credits') {
            rest.signupCredits = 0;
          }
          rest.voucherCode = this.props.checkedCode;
          if (this.props.listing && this.props.listing.id.uuid && currentUser.id.uuid) {
            sendCheckoutSlackNotification({
              listingId: this.props.listing.id.uuid,
              userId: currentUser.id.uuid,
            });
          }

          this.props.onSubmit(rest);
        }}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            onFetchTimeSlots,
            timeZone,
            currentUser,
            listing,
            checkCodeInProgress,
            checkedCode,
            onCheckingVoucher,
            checkCodeErorr,
            onResetCode,
            onEstimateBreakdown,
            estimateBreakdownInProgress,
            estimatedTx,
            requestButtonId,
            listingParams,
            onManageDisableScrolling,
            onReadInsurance,
            onOpenRentalAgreement,
            estimateError,
            rawInitialValues,
            localTimeZone,
            isLongTerm,
            isLongTermBooking,
            monthlyTimeSlots,
            transaction,
            updateBooking,
            getChildTransactionData,
            location,
          } = fieldRenderProps;
          if (estimateError || Object.keys(this.state.timeError).length > 0) {
            this.getErrorEventFired(estimateError, 'estimateError');
          }
          if (estimateError || Object.keys(this.state.timeError).length > 0) {
            this.getErrorEventFired(this.state.timeError, 'timeError');
          }
          if (!this.mounted) {
            form.batch(() => {
              Object.entries(rawInitialValues).forEach(entry => form.change(entry[0], entry[1]));
            });
            this.mounted = true;
          }

          const isInstantBooking = listingIsInstantBooking(listing);

          const { publicData = {} } = listing.attributes;
          const hourlyAvailability = publicData && publicData.hourlyAvailability;

          const requestToBookButtonMessage = isInstantBooking
            ? 'BookingDatesForm.requestToBookInstant'
            : 'BookingDatesForm.requestToBook';

          const {
            guestVerified: verifiedGuest,
            guestUnderVerify: underVerifiedGuest,
          } = currentUserIdentityStatus(currentUser);

          const { signupCredits = 0 } = values;
          let {discountChoice} = values;
          let savedVoucherCode = ""
          if (typeof window !== 'undefined') {
            if(window.localStorage && window.localStorage.getItem('codeType') !== "signUpCode") {
              savedVoucherCode = window.localStorage.getItem('code')
            }
          }
          if(savedVoucherCode && discountChoice !=="credits") {
            discountChoice = "promos"
            values.voucherCode = savedVoucherCode
          }

          const canRequestBooking = currentUserCanRequestToBooking(currentUser);

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          const parentTransactionStartDate = transaction && transaction.booking && transaction.booking.attributes.displayStart;
          const parentTransactionEndDate = location && location.state && location.state.endDate;
          const currentDiffHours = moment(endDate).diff(moment(startDate), 'hours', true);
          const parentTransactionDiffHours = moment(parentTransactionEndDate).diff(moment(parentTransactionStartDate), 'hours', true);
          const diffHours = (currentDiffHours - parentTransactionDiffHours);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate && Object.entries(this.state.timeError).length === 0
              ? {
                unitType,
                unitPrice,
                startDate,
                endDate,
                timeZone,
                listing,
                credits: discountChoice === 'credits' ? signupCredits : 0,
                discount: discountChoice === 'promos' && checkedCode ? checkedCode.discount : 0,
                isPaidAmount: false,
                checkedCode,
              }
              : null;


          const userCanRequestBooking = canRequestBooking && startDate && endDate;
          const bookingInfo =
            bookingData && currentUser && currentUser.id
              ? (() => {
                let isDataNotEqual = !isEqual(this.state.bookingData, bookingData);
                if (isDataNotEqual || (!isDataNotEqual && !estimatedTx && !estimateBreakdownInProgress)) {
                  if (!isOwnListing) {
                    const dummyStartDate = new Date(moment(bookingData.startDate).add(9, 'months'));
                    const dummyEndDate = new Date(moment(bookingData.startDate).add(9, 'months').add(diffHours, 'hours'));

                    onEstimateBreakdown({
                      processAlias: updateBooking ? config.updateBookingChargingProcessAlias : config.masterProcessAlias,
                      bookingStart: updateBooking ? dummyStartDate : bookingData.startDate,
                      bookingEnd: updateBooking ? dummyEndDate : bookingData.endDate,
                      listing,
                      currentUser,
                      credits: bookingData.credits,
                      discount: bookingData.discount,
                      isPaidAmount: bookingData.isPaidAmount,
                      diffHours: diffHours,
                      checkedCode,
                    }).then((result) => {
                      if (result && result.attributes && updateBooking) {
                        getChildTransactionData({
                          lineItems: result.attributes.lineItems,
                          bookingStart: bookingData.startDate,
                          bookingEnd: bookingData.endDate,
                          payinTotal: result.attributes.payinTotal,
                          transactionId: result.id.uuid,
                          transaction: JSON.stringify(result),
                        });
                      }
                    });
                  }
                  this.setState({
                    bookingData: bookingData,
                  });
                }

                return (
                  <div className={css.priceBreakdownContainer}>
                    <h3 className={css.priceBreakdownTitle}>
                      <FormattedMessage id='BookingTimeForm.priceBreakdownTitle' />
                    </h3>
                    <EstimatedBreakdownMaybe
                      unitType={unitType}
                      estimating={estimateBreakdownInProgress}
                      estimatedTx={estimatedTx}
                    />
                  </div>
                );
              })()
              : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper,
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const hasTimeError = Object.keys(this.state.timeError).length > 0 || estimateError;

          const isDeposit = isChargeDeposit(estimatedTx);
          return (
            <Form onSubmit={handleSubmit} currentUser={currentUser} className={classes}>
              {monthlyTimeSlots && timeZone ? (
                <Fragment>
                  <FormSpy currentUser={currentUser} subscription={{ values: true }}
                           onChange={this.handleTimeRangeError} />
                  <FormSpy
                    subscription={{ values: true }}
                    currentUser={currentUser}
                    onChange={props => {
                      const {values} = props;
                      const { discountChoice, signupCredits } = values;
                      if (this.prevDiscountChoice !== discountChoice) {
                        this.prevDiscountChoice = discountChoice;
                        onResetCode();
                        form.batch(() => {
                          form.change('voucherCode', null);
                          form.change('signupCredits', 0);
                        });
                      }
                      if (discountChoice === 'credits' && !signupCredits) {
                        form.batch(() => {
                          form.change(
                            'signupCredits',
                            this.props.currentUser && this.props.currentUser.id ? this.props.currentUser.attributes.credits : 0,
                          );
                        });
                      }
                    }}
                  />
                  <FieldStartDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
                  <FieldEndDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingEndDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : this.beyond6Months}
                    location={location}
                    hourlyAvailability={hourlyAvailability}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
                  {(this.state.showToast) ? (
                    <AlertBox
                      title="Oops!"
                      message="Please login/signup to make a booking"
                      type="info"
                    />
                  ) : null}
                  {!isLongTermBooking && !updateBooking && (
                    <div className={css.fieldsPromos}>
                      <FieldSelect
                        labelClassName={css.label}
                        id='discountChoice'
                        name='discountChoice'
                        label={`Promotions & credits`}
                        className={css.checkDiscount}
                        onChange={()=> {
                          if(!currentUser) {
                            this.setState({
                              showToast: true,
                              showError: false
                            })
                          } else {
                            this.setState({
                              showError: true
                            })
                          }

                          setTimeout(() => {
                            this.setState({
                              showToast: false
                            })
                          }, 2000)
                        }}
                        disabled={
                          // isNewCar ||
                          estimateBreakdownInProgress ||
                          !startDate ||
                          !endDate ||
                          this.state.loading
                        }
                      >
                        <option value='none'>None...</option>
                        <option value='credits'>I want to use my available credits</option>
                        <option value='promos'>I have a promotional voucher</option>
                      </FieldSelect>
                      {discountChoice && discountChoice === 'credits' && signupCredits <= 0 && this.state.showError ?
                        <div className={css.error}>{'No Credit balance'}</div> : ''}
                      {discountChoice === 'promos' && (
                        <React.Fragment>
                          <div className={css.voucherContainer}>
                            <FieldTextInput
                              id="voucherCode"
                              name="voucherCode"
                              className={css.voucherInput}
                              type="text"
                              label="Input your voucher"
                              placeholder="Type your voucher here..."
                              disabled={this.state.loading}
                            />

                            <Button
                              className={css.voucherButton}
                              type='button'
                              inProgress={checkCodeInProgress}
                              disabled={
                                estimateBreakdownInProgress || !values.voucherCode
                              }
                              ready={!!checkedCode}
                              onClick={() => {
                                const { voucherCode, ...rest } = values;
                                const startDate = timestampToDate(rest.bookingStartTime);
                                const endDate = timestampToDate(rest.bookingEndTime);
                                if(currentUser) {
                                  onCheckingVoucher({
                                    code: voucherCode,
                                    data: {
                                      startDate,
                                      endDate,
                                    },
                                  });
                                } else {
                                  this.setState({
                                    showToast: true
                                  })
                                }
                              }}
                            >
                              Check
                            </Button>
                          </div>
                          {checkCodeErorr && (
                            <p className={classNames(css.smallPrintForMember, css.error)}>
                              Invalid voucher code
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  {bookingInfo}
                  {Object.keys(this.state.timeError).length ? (
                    <div className={css.error}>
                      {Object.keys(this.state.timeError).join('. \n')}
                    </div>
                  ) : null}


                  {estimateError && estimateError.status === 411 ? (
                    <div className={css.error}>
                      You already have an accepted booking for the dates and times above.
                    </div>
                  ) : estimateError && estimateError.status === 413 ? (<div className={css.error}>
                    Since your profile is not verified yet, you need to book a trip at least 2 hours
                    prior to the trip start time.
                  </div>) : estimateError && estimateError.apiErrors[0] && estimateError.apiErrors[0].details === 'positive-or-zero-payout?'
                    ? <div className={css.error}>Only bigger amount of hours alowed</div>
                    : estimateError ? (
                      <div className={css.error}>Something went wrong. Please refresh the page</div>
                    ) : null}
                  {updateBooking && <div className={css.submitSection}>
                    <div className={css.submitSectionInner}>
                      {transaction ? (
                        <div className={css.submitSectionPrice}>
                          <PrimaryButton
                            type='submit'
                            disabled={
                              hasTimeError || estimateBreakdownInProgress || !userCanRequestBooking
                            }
                            inProgress={estimateBreakdownInProgress}
                            id={requestButtonId}
                          >
                            <FormattedMessage id={requestToBookButtonMessage} />
                          </PrimaryButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  }
                  {!updateBooking && <div className={submitButtonClasses}>
                    {isDeposit && !isLongTermBooking ? null : null}
                    {startDate && endDate && estimatedTx ? (
                      <p className={css.smallPrintForMember}>
                        <FormattedMessage id='BookingPanel.memberUseDrivelah' />
                      </p>
                    ) : null}

                    {currentUser && currentUser.id ? (
                      <PrimaryButton
                        type='submit'
                        disabled={
                          hasTimeError || estimateBreakdownInProgress || !userCanRequestBooking
                        }
                        inProgress={estimateBreakdownInProgress}
                        id={requestButtonId}
                      >
                        <FormattedMessage id={requestToBookButtonMessage} />
                      </PrimaryButton>
                    ) : (
                      <NamedLink
                        name='LoginPage'
                        to={{
                          state: {
                            startDate,
                            endDate,
                            startTime,
                            endTime,
                            params: listingParams,
                            isFromListingPage: true,
                          },
                        }}
                      >
                        <PrimaryButton type='button'>
                          <FormattedMessage id={requestToBookButtonMessage} />
                        </PrimaryButton>
                      </NamedLink>
                    )}

                    {!isInstantBooking && (
                      <p className={css.smallPrint}>
                        <FormattedMessage id='BookingPanel.noCharge' />
                      </p>
                    )}
                  </div>}
                </Fragment>
              ) : null}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {!updateBooking && (<InsurancePanelNew
                listing={listing}
                showInsurance={true}
                insuranceType={publicData.insurance}
                onManageDisableScrolling={onManageDisableScrolling}
                onReadInsurance={onReadInsurance}
              />)}
              {!updateBooking && (<div className={css.rentalAgreementLink}>
                <span onClick={onOpenRentalAgreement}>
                  <FormattedMessage id='BookingDatesForm.rentalAgreementLink' />
                </span>
              </div>)}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  monthlyTimeSlots: null,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  isNewCar: false,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  isLongTerm: bool,
  isLongTermBooking: bool,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
