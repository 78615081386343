import React, { Component } from 'react';
import PropTypes, { array, arrayOf, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import driveTogetherLogo from '../../assets/donationPage/drive-together-logo.svg';
import Button from '../Button/Button';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  getUserTxRole,
  TRANSITION_ACCEPT,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  txAfterCancelUpdateTrip,
  txHasBeenDelivered,
  txHasFirstReviewedByCustomer,
  txHasFirstReviewedByProvider,
  txInVerificationProcess,
  txIsAccepted,
  txIsAcceptedUpdateBookingBeforeDropOff,
  txIsAcceptedUpdateBookingBeforePickUp,
  txIsAfterConfirmPickUp,
  txIsAutoAcceptedUpdateBookingBeforeDropOff,
  txIsAutoAcceptedUpdateBookingBeforePickUp,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsCancelledUpdateBookingBeforeDropOff,
  txIsCancelledUpdateBookingBeforePickUp,
  txIsCompleted,
  txIsDeclined,
  txIsEnquired,
  txIsExpired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPickedUp,
  txIsRequested,
  txIsShu,
  txIsUnverifiedInstantRequested,
  txIsUnverifiedRequested,
  txIsUnverifiedSubmitted,
  txIsUnverifiedSubmittedInstant,
  txIsWithdrawn,
  txIsWithdrawnByAdmin,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
  txIsReturnedBackBeforePickUp,
} from '../../util/transaction';
import {
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  txIsBeingPickedUpLTF,
  txIsDeclinedLTF,
  txIsExpiredLTF,
  txIsPickedUpLTF,
  txIsRequestedLTF,
  txIWaitingRequestPickupLTF,
  txLastTransition,
} from '../../util/transactionLongTermFirst';
import {
  TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
  txHasFirstReviewedByCustomerLTL,
  txHasFirstReviewedByProviderLTL,
  txIsAfterTripEndLTL,
  txIsBeingDropOffLTL,
  txIsCompletedLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { propTypes, VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import {
  checkOwnListing,
  ensureListing,
  ensureTransaction,
  ensureUser,
  getBookingConfig,
  listingIsLongTermRental,
  userDisplayNameAsString,
} from '../../util/data';
import { convertMoneyToNumber } from '../../util/currency';
import { isMobileSafari } from '../../util/userAgent';
import {
  BookingInfoSection,
  ExternalLink,
  ModalCancel,
  ModalCancelPolicy,
  ModalHelp,
  NamedLink,
  PartnerInfo,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import {
  CANCEL_BOOKING_BUTTON_ID,
  EVENT_BOOK_REQUEST_WITHDRAWN_GUEST,
  EVENT_SEARCH_READ_INSURANCE,
  EVENT_TRIP_DISPUTED_GUEST,
  EVENT_TRIP_DISPUTED_HOST,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_DROPOFF_REQUEST_RECEIVED,
  EVENT_TRIP_DROPOFF_REQUEST_SENT,
  EVENT_TRIP_GUEST_CANCEL_GUEST,
  EVENT_TRIP_GUEST_REVIEW_GUEST,
  EVENT_TRIP_GUEST_REVIEW_HOST,
  EVENT_TRIP_HOST_CANCEL_HOST,
  EVENT_TRIP_HOST_REVIEW_GUEST,
  EVENT_TRIP_HOST_REVIEW_HOST,
  EVENT_TRIP_MESSAGE_SENT_GUEST,
  EVENT_TRIP_MESSAGE_SENT_HOST,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_PICKUP_REQUEST_RECEIVED,
  EVENT_TRIP_PICKUP_REQUEST_SENT,
  SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST,
  SERVER_EVENT_TRIP_GUEST_CANCEL_HOST,
  SERVER_EVENT_TRIP_HOST_CANCEL_GUEST,
  TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
  TRIP_PUBLISH_REVIEW_BUTTON_ID,
  TRIP_SEND_MESSAGE_BUTTON_ID,
  TRIP_SUBMIT_DISPUTE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';

import {
  initiateEventFromListing,
  initiateEventFromTransaction,
  pushGTMTripEvent,
} from '../../util/gtm/gtmHelpers';
import AddressLinkMaybe from './AddressLinkMaybe';
import FeedSection from './FeedSection';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_CANCELED,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_ONGOING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_REQUESTED,
  HEADING_WITHDRAWN,
  HEADING_WITHDRAWN_BY_ADMIN,
} from './PanelHeading';
import {
  HEADING_ACCEPTED_UPDATE_BOOKING,
  HEADING_DECLINED_UPDATE_BOOKING,
  HEADING_REQUEST_UPDATE_BOOKING,
} from '../TransactionPanel/PanelHeading';
import css from './TripPanel.css';
import { dropoffTransitions, LONG_TERM_STATUS_FINISH } from './DropOffButtonsMaybe';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import routeConfiguration from '../../routeConfiguration';
import { createSlug, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import ListingMap from './ListingMap';
import CustomerMap from './CustomerMap';
import BookingStatus from './BookingStatus';
import InfoSection from './InfoSection';
import ImportantLinksSection from './ImportantLinksSection';
import UpdateBookingButtonsMaybe from './UpdateBookingButtonsMaybe';
import ButtonsSection from './ButtonsSection';
import { pickupTransitions } from './PickupButtonsMaybe';
// import { LockUnlockButtonMaybe } from './LockUnlockButtonsMaybe';
import TripDetailsSection from './TripDetailsSection';
import { getNextCancelTransition, getNextInteractableTransition } from './transitionHelper';

import PickUpSection from './PickUpSection';
import DropOffSection from './DropOffSection';
import ModalReasonDispute from './ModalReasonDispute';
import { LockUnlockButtonMaybe } from './LockUnlockButtonsMaybe';
import { types as sdkTypes } from '../../util/sdkLoader';
import { richText } from '../../util/richText';
import { priceData } from '../../containers/ListingPage/ListingPage.helper';
import { fetchShuLastLocation } from '../../util/shuStatus';
import { AccountSettingDriverVerificationPage } from '../../containers';

const { LatLng, UUID } = sdkTypes;

const SHU_STATUS_DISPLAY_STATUS = {
  INIT: 0,
  SUCCESS: 1,
  ERROR: 2,
  LOADING: 3,
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;
  let otherUser = null;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    otherUser,
  };
};

export const getTransitions = transaction => {
  const lastTransition = txLastTransition(transaction);
  const isDrivelahGo =
    transaction.attributes &&
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.isDrivelahGo;
  switch (lastTransition) {
    case TRANSITION_ACCEPT:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
      return isDrivelahGo
        ? {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
          }
        : {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
          };
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      return isDrivelahGo
        ? {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
          }
        : {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
          };
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_LTF_REQUEST_PICK_UP,
      };
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_LTF_CONFIRM_PICK_UP,
      };
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_REQUEST_DROP_OFF,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_REQUEST_DROP_OFF_DLGO,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF_DLGO,
      };
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_REQUEST_DROP_OFF,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case TRANSITION_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_CONFIRM_DROP_OFF, //[(TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE)],
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_DISPUTE,
      };
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_LTL_TRIP_END:
    case TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_LTL_REQUEST_DROP_OFF],
      };
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_LTL_CONFIRM_DROP_OFF],
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
      };
    case TRANSITION_LTL_TRIP_END:
    case TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_LTL_REQUEST_DROP_OFF],
      };
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_LTL_CONFIRM_DROP_OFF],
      };
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
    case TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF:
      return isDrivelahGo
        ? {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF_DLGO,
          }
        : {
            [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_DROP_OFF,
          };
    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      };
    default:
      return {};
  }
};

export class TripPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isCancelPolicyOpen: false,
      reviewSubmitted: false,
      isStatic: true,
      showCancelModal: false,
      showCancelHelpModal: false,
      showDisputeModal: false,
      showImgModal: false,
      showImgModalUrl: null,
      reasonDispute: '',
      currentPhotos: [],
      tripPhotos: [],
      exteriorPhotos: [],
      selfieWithId: [],
      interiorPhotos: [],
      odometerPhotos: [],
      fuelReceiptPhotos: [],
      fuelGaugePhotos: [],
      pickupProviderPhoto: [],
      reasonDisputePhotoUrls: [],
      isOpenRentalAgreement: false,
      userCloseModal: false,
      numberOdometer: null,
      isReadMore: null,
      showPickUpSection: false,
      showDropOffSection: false,
      activePhotoSection: [],
      editWithAdditionalTime: false,
      isEditBookingActive: false,
      isDonateTransactionActive: false,
      shuStatusLocation: {
        status: SHU_STATUS_DISPLAY_STATUS.INIT,
        location: {},
        address: '',
      },
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.shouldShowPickUpSection = this.shouldShowPickUpSection.bind(this);
    this.shouldShowDropOffSection = this.shouldShowDropOffSection.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    this.initShuStatusLocation(this.props.transaction);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.userCloseModal) {
      if (!this.state.isReviewModalOpen) {
        const { transaction, transactionRole } = nextProps;
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        if (transaction) {
          const isCompleted = txIsCompleted(transaction) || txIsCompletedLTL(transaction);
          const isReviewedByCustomer =
            txHasFirstReviewedByCustomer(transaction) ||
            txHasFirstReviewedByCustomerLTL(transaction);
          const isReviewedByProvider =
            txHasFirstReviewedByProvider(transaction) ||
            txHasFirstReviewedByProviderLTL(transaction);

          if (isCompleted) {
            if (isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          } else {
            if (isReviewedByCustomer && isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isReviewedByProvider && isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          }
        }
      }
    }
  }

  handleOpenStatusModal = idBtn => {
    document.getElementById(idBtn).click();
  };

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    let currentTransaction = ensureTransaction(transaction);
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    if (isFinishLongTerm) {
      currentTransaction = transaction && transaction.currentChildTransaction;
    }
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const events =
      transactionRole === 'provider'
        ? [EVENT_TRIP_HOST_REVIEW_GUEST, EVENT_TRIP_HOST_REVIEW_HOST]
        : [EVENT_TRIP_GUEST_REVIEW_HOST, EVENT_TRIP_GUEST_REVIEW_GUEST];

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        pushGTMTripEvent({
          props: this.props,
          event: events[0],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
          userDiffActionTaker: true,
        });
        pushGTMTripEvent({
          props: this.props,
          event: events[1],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
        });
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      // window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const event =
      transactionRole === 'provider' ? EVENT_TRIP_MESSAGE_SENT_HOST : EVENT_TRIP_MESSAGE_SENT_GUEST;

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        pushGTMTripEvent({
          props: this.props,
          event: event,
          transaction,
          buttonId: TRIP_SEND_MESSAGE_BUTTON_ID,
          existTransaction: true,
        });
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleChooseDisputePhoto(fileUrl) {
    const isExisted = this.state.reasonDisputePhotoUrls.find(url => url === fileUrl);
    if (isExisted) {
      this.setState({
        reasonDisputePhotoUrls: this.state.reasonDisputePhotoUrls.filter(url => url !== fileUrl),
      });
    } else {
      this.setState({
        reasonDisputePhotoUrls: [...this.state.reasonDisputePhotoUrls, fileUrl],
      });
    }
  }

  onPushDisputeEventGTM = () => {
    const { transaction, transactionRole } = this.props;
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_HOST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_GUEST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  onPushPickUpAndDropOffEventGTM = (transaction, transitionName) => {
    let event = [];
    if (transitionName && transitionName.includes('request-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_SENT, EVENT_TRIP_PICKUP_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_CONFIRM, EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST];
    } else if (transitionName && transitionName.includes('request-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_SENT, EVENT_TRIP_DROPOFF_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_CONFIRM, EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST];
    }
    pushGTMTripEvent({
      props: this.props,
      event: event[0],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: event[1],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
      userDiffActionTaker: true,
    });
  };

  onCancelTripGTM = isAccepted => {
    const { transaction, transactionRole } = this.props;
    let events;
    if (isAccepted) {
      events =
        transactionRole === 'provider'
          ? [SERVER_EVENT_TRIP_HOST_CANCEL_GUEST, EVENT_TRIP_HOST_CANCEL_HOST]
          : [SERVER_EVENT_TRIP_GUEST_CANCEL_HOST, EVENT_TRIP_GUEST_CANCEL_GUEST];
      pushGTMTripEvent({
        props: this.props,
        event: events[0],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      pushGTMTripEvent({
        props: this.props,
        event: events[1],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
      });
    } else {
      events = [SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST, EVENT_BOOK_REQUEST_WITHDRAWN_GUEST];
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[0],
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[1],
        existTransaction: true,
      });
    }
  };

  shouldShowPickUpSection(value) {
    if (value.target) {
      this.setState({
        showPickUpSection: !this.state.showPickUpSection,
      });
    } else {
      this.setState({
        showPickUpSection: value,
      });
    }
  }

  shouldShowDropOffSection(value) {
    if (value.target) {
      this.setState({
        showDropOffSection: !this.state.showDropOffSection,
      });
    } else {
      this.setState({
        showDropOffSection: value,
      });
    }
  }

  setActivePhotoSection = photos => {
    const { exteriorPhotos } = this.state;
    const exteriorPhotosBool = photos[0].type === 'exteriorPhotos';
    const interiorPhotosBool = photos[0].type === 'interiorPhotos';
    const odometerPhotosBool = photos[0].type === 'odometerPhotos';
    const fuelGaugePhotosBool = photos[0].type === 'fuelGaugePhotos';
    const fuelReceiptPhotosBool = photos[0].type === 'fuelReceiptPhotos';
    const selfieWithIdBool = photos[0].type === 'selfieWithId';
    const pickupProviderPhotosBool = photos[0].type === 'pickupProviderPhoto';
    const index = photos[0].id;
    const currentActivePhotoSection = this.state.activePhotoSection;
    const totatalExteriorPhotos = exteriorPhotosBool && [...exteriorPhotos, ...photos];
    if (exteriorPhotosBool && totatalExteriorPhotos.length >= 4) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    if (
      (interiorPhotosBool ||
        odometerPhotosBool ||
        fuelGaugePhotosBool ||
        fuelReceiptPhotosBool ||
        selfieWithIdBool ||
        pickupProviderPhotosBool) &&
      photos.length >= 1
    ) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    this.setState({
      currentPhotos: [...this.state.currentPhotos, ...photos],
    });
  };

  handlePaymentForChildLongTerm = () => {
    const {
      callSetInitialValues,
      onInitializeCardPaymentData,
      onResetCode,
      history,
      transaction,
      childTransaction: {},
    } = this.props;
    const childTransaction = transaction.nextTransaction;
    const listing = get(transaction, 'listing');
    const location = get(listing, 'attributes.publicData.location', {});
    const bookingStart = get(childTransaction, 'booking.attributes.displayStart');
    const bookingEnd = get(childTransaction, 'booking.attributes.displayEnd');
    const transactionId = get(childTransaction, 'id.uuid');
    if (!transactionId) {
      window && window.location.reload();
    }
    const initialValues = {
      parentTransaction: transaction,
      listing,
      bookingData: {
        signupCredits: 0,
        voucherCode: null,
      },
      bookingDates: {
        bookingStart: new Date(bookingStart),
        bookingEnd: new Date(bookingEnd),
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutLongTermPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    onResetCode();
    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutLongTermPage',
        routes,
        { id: listing.id.uuid, transactionId, slug: createSlug(listing.attributes.title) },
        { searchPageParams: location.search }
      )
    );
  };

  getChildTransactionData = value => {
    const mapedLineItems = value.lineItems.map(item => {
      return {
        ...item,
        lineTotal: convertMoneyToNumber(item.lineTotal),
        unitPrice: convertMoneyToNumber(item.unitPrice),
      };
    });

    const childTransaction = {
      lineItems: mapedLineItems,
      bookingStart: moment(value.bookingStart).format('YYYY-MM-DD HH:mm:ss'),
      bookingEnd: moment(value.bookingEnd).format('YYYY-MM-DD HH:mm:ss'),
      payinTotal: convertMoneyToNumber(value.payinTotal),
      transactionId: value.transactionId,
      transaction: value.transaction,
    };

    this.setState({
      childTransaction,
    });
  };

  fetchCurrentGeoLocation = async (tId, ld) => {
    const setStateForShuLocation = (status, location = {}, address = '') => {
      this.setState({
        shuStatusLocation: {
          status,
          location,
          address,
        },
      });
    };

    if (
      tId &&
      ld &&
      ld.attributes &&
      ld.attributes.publicData &&
      ld.attributes.publicData.license_plate_number
    ) {
      try {
        const licencePlateNumber = ld.attributes.publicData.license_plate_number;
        setStateForShuLocation(SHU_STATUS_DISPLAY_STATUS.LOADING);
        const response = await fetchShuLastLocation(tId, licencePlateNumber);
        if (response && response.data) {
          const shuResponse = response.data.shuResponse;
          const shuAddress = response.data.address;
          setStateForShuLocation(SHU_STATUS_DISPLAY_STATUS.SUCCESS, shuResponse, shuAddress);
        } else {
          setStateForShuLocation(SHU_STATUS_DISPLAY_STATUS.ERROR);
        }
      } catch (error) {
        setStateForShuLocation(SHU_STATUS_DISPLAY_STATUS.ERROR);
      }
    }
  };

  checkLatLngParams = (lat, lng) => {
    return lat && !isNaN(lat) && lng && !isNaN(lng);
  };

  isShuStatusPresent = transaction => {
    return (
      transaction &&
      transaction.attributes &&
      transaction.attributes.metadata &&
      transaction.attributes.metadata['shuStatus1hour']
    );
  };

  initShuStatusLocation = transaction => {
    // Here we have to check if it is in hour or 15 minutes
    if (this.isShuStatusPresent(transaction)) {
      const shuStatus = transaction.attributes.metadata['shuStatus1hour'];
      const shuAddress = transaction.attributes.metadata['pickupSHULocation'];
      const { last_known_gps_latitude: gpsLat, last_known_gps_longitude: gpsLng } = shuStatus;
      this.setState({
        shuStatusLocation: {
          status: SHU_STATUS_DISPLAY_STATUS.SUCCESS,
          location: {
            lat: Number(gpsLat),
            lng: Number(gpsLng),
          },
          address: shuAddress,
        },
      });
    }
  };

  getLocationData = listing => {
    const getLocation = () => {
      return (
        (listing &&
          listing.attributes &&
          listing.attributes.publicData &&
          listing.attributes.publicData.location) ||
        {}
      );
    };

    const location = getLocation();
    const pc = location.postalCode || '';
    const address = location.address || '';
    return { pc, defaultAddress: address };
  };

  getAccurateGeoLocation = (transaction, listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    if (this.isShuStatusPresent(transaction)) {
      const shuStatus = transaction.attributes.metadata['shuStatus1hour'];
      const shuAddress = transaction.attributes.metadata['pickupSHULocation'];
      if (typeof shuStatus === 'object') {
        const { last_known_gps_latitude: gpsLat, last_known_gps_longitude: gpsLng } = shuStatus;
        if (this.checkLatLngParams(gpsLat, gpsLng)) {
          return {
            geolocation: {
              ...geolocation,
              lat: Number(gpsLat),
              lng: Number(gpsLng),
            },
            address: shuAddress || '',
          };
        }
      }
    }

    const { shuStatusLocation } = this.state;
    const {
      last_known_gps_latitude: sGpsLat,
      last_known_gps_longitude: sGpsLng,
    } = shuStatusLocation.location;
    const shuStateAddress = shuStatusLocation.address;
    if (this.checkLatLngParams(sGpsLat, sGpsLng)) {
      return {
        geolocation: {
          ...geolocation,
          lat: Number(sGpsLat),
          lng: Number(sGpsLng),
        },
        address: shuStateAddress || '',
      };
    }

    return { geolocation, address: defaultAddress };
  };

  getApproximateGeoLocation = (listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    let newAddress = (defaultAddress || '')
      .split(',')
      .filter(Boolean)
      .slice(-2)
      .map(word => {
        return word.trim();
      });
    newAddress = newAddress.join(', ');
    return {
      geolocation,
      address: newAddress,
    };

    // postalcode commented.
    // const postalRecord = findApproximatePostalRecord(pc);
    // const { lat: pLat, lng: pLng, address: pAddress, postCode: pPC } = postalRecord
    // if(this.checkLatLngParams(pLat , pLng)){
    //   return {geolocation: {
    //     ...geolocation,
    //     lat: Number(pLat),
    //     lng: Number(pLng)
    //   }, address: `${pAddress}, ${pPC}`}
    // }else{
    //   return {geolocation , address: defaultAddress}
    // }
  };

  render() {
    const {
      fetchLastUpdateBookingTxInProgress,
      cancelAllUpdateBookingTxsInProgress,
      bookingUpdateTxs,
      lastBookingUpdate,
      acceptUpdateBookingInProgress,
      cancelUpdateBookingInProgress,
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptButtonName,
      declineButtonName,
      onTransit,
      transitInProgress,
      transitError,
      keyRulesConfig,
      onSendMessage,
      ownListing,
      nextTransitions,
      transactionWithoutRestore,
      acceptSaleGTM,
      declineSaleGTM,
      onHandlePaymentFuel,
      isMobileLayout,
      isTabletLayout,
      history,
      onUploadInteriorPhotoToMetadata,
      uploadInteriorPhotoProgress,
      uploadInteriorSuccess,
      onFetchCancelUpdateBooking,
      onFetchTimeSlots,
      monthlyTimeSlots,
      timeSlots,
      checkedCode,
      checkCodeInProgress,
      checkCodeErorr,
      unitType,
      onCheckingVoucher,
      onEstimateBreakdown,
      estimateBreakdownInProgress,
      estimateError,
      estimatedTx,
      fetchTimeSlotsInProgress,
      getListing,
      customerUpdateBookingInProgress,
      transactionUpdateBooking,
      listingForMap,
      onAcceptUpdateBooking,
      onCancelUpdateBooking,
      onCancelAllUpdateBookings,
      isLimitedUser,
      onCancelUpdateBookingByAdmin,
      onAcceptUpdateBookingByAdmin,
      isCancelUpdateBookingByAdminPending,
      isAcceptUpdateBookingByAdminPending,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const transactionId = get(currentTransaction, 'id.uuid');
    const lastTransition = txLastTransition(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    let insuranceType = currentListing.attributes.publicData.insurance;
    const timeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );
    const fuelType = currentListing.attributes.publicData.fuelType;
    const customerLocation = currentTransaction.attributes.protectedData.customerLocation;
    // const customerGeolocation =
    //   customerLocation &&
    //   Object.assign({ _sdkType: 'LatLng' }, customerLocation.selectedPlace.origin);

    const isDelivery = currentListing.attributes.publicData.delivery;
    const isLiveListing = currentListing.id && currentListing.attributes.metadata.live;
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const localTimeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );
    const isLongTermListing = listingIsLongTermRental(currentListing);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const bookingConfig = getBookingConfig(currentListing);

    const customerAddress = customerLocation && customerLocation.selectedPlace;
    const listingId = currentListing.id.uuid;
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    const stateData = this.stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned);
    const currentUserRole = getUserTxRole(currentUser.id, transaction);
    const isInstantBooking = currentListing.attributes.publicData.instantBooking;
    const isDrivelahGo =
      currentTransaction.attributes.protectedData.isDrivelahGo ||
      currentListing.attributes.metadata.isDrivelahGo;
    const shuLocationAvailble = currentTransaction.attributes.metadata && currentTransaction.attributes.metadata.pickupSHULocation
    const isHourlyBooking = get(currentTransaction, 'attributes.protectedData.hourlyBooking');
    const depositTx = get(currentTransaction, 'attributes.protectedData.depositTx');
    const chargedDepositTx = get(currentUser, 'attributes.profile.metadata.depositChildTx');
    const showDepositTxSection = !isProvider && depositTx !== undefined && chargedDepositTx !== undefined && depositTx === chargedDepositTx;
    // console.log('check deposiot tx data', showDepositTxSection);
    const { displayStart } = get(currentTransaction, 'booking.attributes', {});
    const isTimeToShowTransitionPickup = moment().isSameOrAfter(
      moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
    );

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const currentChildTransaction = get(
      currentTransaction,
      'attributes.metadata.currentChildTransaction'
    );
    const nextCancelTransition = getNextCancelTransition(
      currentChildTransaction ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    console.log(
      'NXXXXXX',
      nextCancelTransition,
      currentTransaction.currentChildTransaction,
      currentTransaction
    );
    const haveNextCancelTransit = nextCancelTransition.length > 0;

    const transactionForCancel = !isLongTerm
      ? transactionWithoutRestore
      : currentChildTransaction
      ? currentTransaction.currentChildTransaction
      : currentTransaction;

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
      otherUser,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = listingForMap.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    let modifiedGeoLocation = geolocation;
    let locationAddress = location.address || undefined;
    const currentTime = moment();
    const pickupTime = moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes');
    const showExactShuLocation = pickupTime.diff(currentTime, 'minutes') <= 60;
    const showFetchLiveLocationButton = pickupTime.diff(currentTime, 'minutes') <= 15 && !showExactShuLocation
    const isTripYetToStart = true;

    if (isDrivelahGo) {
      if (showExactShuLocation) {
        const exactLocation = this.getAccurateGeoLocation(
          currentTransaction,
          listingForMap,
          geolocation
        );
        modifiedGeoLocation = exactLocation.geolocation;
        locationAddress = exactLocation.address;
      } else {
        const approxLocation = this.getApproximateGeoLocation(listingForMap, geolocation);
        modifiedGeoLocation = approxLocation.geolocation;
        locationAddress = approxLocation.address;
      }
    }

    const listingRegistrationNumber = currentListing.attributes.publicData.license_plate_number;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });
    const rentalAgreementFileLink =
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.rentalAgreementFileLink;

    const updateBookingButtons = (
      <UpdateBookingButtonsMaybe
        showButtons={stateData.showBookingButtons}
        transaction={currentTransaction}
        acceptInProgress={acceptUpdateBookingInProgress}
        declineInProgress={cancelUpdateBookingInProgress}
        onAcceptUpdateBooking={onAcceptUpdateBooking}
        onCancelUpdateBooking={onCancelUpdateBooking}
        bookingUpdateTxs={bookingUpdateTxs}
        intl={intl}
        history={history}
        isLimitedUser={isLimitedUser}
        onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
        onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
        isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
        isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
      />
    );

    const { price = null, title = '' } = currentListing.attributes;
    const { longTermRental } = currentListing.attributes.publicData;
    const { formattedPrice, priceTitle } = priceData(
      price,
      intl,
      // longTermRental && shouldShowPricePerMonth,
      true,
      currentListing
    );
    const onReadInsurance = () => {
      initiateEventFromListing({
        props: this.props,
        listing: currentListing,
        event: EVENT_SEARCH_READ_INSURANCE,
        isHost: checkOwnListing(currentUser, currentListing),
      });
    };
    const checkValidateBooking = () => {
      const emailVerificationNeeded = !!currentUser.id && !currentUser.attributes.emailVerified;

      const mobileInputNeeded = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumber
        ? true
        : false;
      const mobileUnverified = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
          !currentUser.attributes.profile.protectedData.phoneNumberVerified
        ? true
        : false;
      const mobileVerificationNeeded = mobileUnverified; //Show that mobile need verified or not

      // if (emailVerificationNeeded || mobileVerificationNeeded || mobileInputNeeded) {
      //   this.props.onOpenMissingInfomationModal(true);
      //   return false;
      // }
      return true;
    };
    const bookingUpdateSubTitle = (
      <div className={css.updateSubTitle}>
        <FormattedMessage id="TransactionPanel.bookingSubTitle" />
      </div>
    );
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: 16,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="TransactionPanel.bookingTitle" values={{ title: richTitle }} />
    );

    const donateSection = (
      <div className={css.donateAndHelp}>
        <div className={css.donateAndHelpHeader}>
          <div className={css.headerThumb}>
            <img src={driveTogetherLogo} alt="" />
          </div>
          <div className={css.headerTitle}>
            Help the people of Ukraine. Together we make a difference
          </div>
        </div>
        <div className={css.donateAndHelpBody}>
          <p>
            We believe in the power of sharing and what it can do to solve some of the most acute
            problems around us. In these times of crises, Drive mate is donating all its March and
            April earnings to help people with food, shelter, medical care and clean drinking water.
          </p>
          <p>Join us and donate directly for the people of Ukraine.</p>
        </div>
        <div className={css.donateAndHelpFooter}>
          <div className={css.bottom}>
            <NamedLink name="DonationPage" className={css.underlinedButton} newTab={true}>
              Learn more
            </NamedLink>
          </div>
          <div className={css.bottom}>
            <Button
              className={css.donateButton}
              onClick={() => this.setState({ isDonateTransactionActive: true })}
            >
              Donate Now
            </Button>
          </div>
        </div>
      </div>
    );

    const isDeliveryTransaction = get(currentTransaction, 'attributes.protectedData.isDelivery');
    const additionalId =
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.additionalTimeTransactionId;
    const hasAdditionalTransaction = !!additionalId;
    const { editWithAdditionalTime } = this.state;

    const classes = classNames(rootClassName || css.root, className);
    const actionButtonClasses = classNames(css.actionButtons);
    const nextInteractableTransitions = getNextInteractableTransition(
      isFinishLongTerm ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextTransit = nextInteractableTransitions.length > 0;

    console.log('Next transition', haveNextTransit, nextInteractableTransitions);
    const validTripPhotos =
      currentTransaction.attributes.protectedData.photoObjects &&
      currentTransaction.attributes.protectedData.photoObjects.length > 0;
    const metaPhotoObject =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.photoObjects;
    const interiorPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['interiorPhotos']).length > 0)
      : [];
    const odometerPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['odometerPhotos']).length > 0)
      : [];
    const fuelGaugePhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['fuelGaugePhotos']).length > 0)
      : [];
    const tripPhotosProtectedData = validTripPhotos
      ? currentTransaction.attributes.protectedData.photoObjects
      : [];
    const allTripPhotos = [...tripPhotosProtectedData];

    const transitionMaybe = get(getTransitions(transaction), `${currentUserRole}`, null);

    const confirmTransitions = [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_CONFIRM_DROP_OFF,
      TRANSITION_REQUEST_DROP_OFF_DLGO,
    ];
    const needInputOdometer =
      get(currentTransaction, 'attributes.protectedData.payForFuel', false) &&
      [...pickupTransitions, ...dropoffTransitions].includes(nextInteractableTransitions[0]);

    const inputOdometerInvalid =
      !confirmTransitions.includes(nextInteractableTransitions[0]) &&
      needInputOdometer &&
      (isEmpty(this.state.numberOdometer) || isNaN(this.state.numberOdometer));

    const isShowPartnerInfo =
      !txIsRequested(transaction) &&
      !txIsDeclined(transaction) &&
      !txIsExpired(transaction) &&
      !txIsRequestedLTF(transaction) &&
      !txIsDeclinedLTF(transaction) &&
      !txIsExpiredLTF(transaction);

    const showSectionUploadCar =
      isShowPartnerInfo &&
      ((txIWaitingRequestPickup(currentTransaction) && isCustomer) ||
        txIsBeingPickedUp(currentTransaction) ||
        txIsAfterConfirmPickUp(currentTransaction) ||
        (txIWaitingRequestPickupLTF(currentTransaction) && isCustomer) ||
        txIsBeingPickedUpLTF(currentTransaction) ||
        txIWaitingRequestDropoffLTL(
          currentTransaction && currentTransaction.currentChildTransaction
        ) ||
        txIsAfterTripEndLTL(currentTransaction && currentTransaction.currentChildTransaction));

    const isShowUploadModal =
      (((txIWaitingRequestPickup(currentTransaction) ||
        txIWaitingRequestPickupLTF(currentTransaction)) &&
        isCustomer) ||
        ((txIsBeingPickedUp(currentTransaction) || txIsBeingPickedUpLTF(currentTransaction)) &&
          isProvider) ||
        ((txIWaitingRequestDropoff(currentTransaction) ||
          txIWaitingRequestDropoffLTL(
            currentTransaction && currentTransaction.currentChildTransaction
          )) &&
          isCustomer) ||
        txIsBeingDropOff(currentTransaction) ||
        (txIsBeingDropOffLTL(currentTransaction && currentTransaction.currentChildTransaction) &&
          isProvider)) &&
      haveNextTransit;

    const createListingLink = (
      listingId,
      label,
      listingDeleted,
      searchParams = {},
      className = ''
    ) => {
      if (!listingDeleted) {
        const params = { id: listingId, slug: createSlug(label) };
        const to = { search: stringify(searchParams) };
        return (
          <NamedLink className={css.bookingListingTitle} name="ListingPage" params={params} to={to}>
            {label}
          </NamedLink>
        );
      } else {
        return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
      }
    };

    const CarAddressData = props => {
      const {
        shuStatusLocation,
        fetchCurrentAddress,
        address,
        showExactShuLocation,
        showFetchLiveLocationButton,
        hrefToGoogleMaps,
      } = props;
      const { status } = shuStatusLocation || {};

      if (showExactShuLocation) {
        if (status === SHU_STATUS_DISPLAY_STATUS.SUCCESS) {
          return (
            <div className={css.linkAddressColor}>
              <ExternalLink className={css.underlineLink} href={hrefToGoogleMaps}>
                {address}
              </ExternalLink>
              <div className={css.pickUpDropoffInstruction}>
                <FormattedMessage id="TripPanel.carPickupDropoffInstruction" />
              </div>
            </div>
          );
        } else {
          const isLoading = status === SHU_STATUS_DISPLAY_STATUS.LOADING;
          const isError = status === SHU_STATUS_DISPLAY_STATUS.ERROR;

          return (
            <div
              className={css.liveLocationButtonConitaner}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {isLoading ? (
                <div className={css.fetchCarLiveLocationLoader}></div>
              ) : (
                <>
                   {showFetchLiveLocationButton &&  <a onClick={fetchCurrentAddress} className={css.underlineLink}>
                    <FormattedMessage id="TripPanel.fetchCarLiveLocation" />
                  </a>}
                  {isError && (
                    <div className={css.fetchCarLiveLocationError}>
                      <FormattedMessage id="TripPanel.failedToFetchCarLiveLocation" />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        }
      } else {
        return (
          <div className={classNames(css.linkAddressColor, css.approximateLocationContainer)}>
            <div>{address}</div>
            <div className={css.pickUpDropoffInstruction}>
              <FormattedMessage id="TripPanel.carApproxLocationInstruction" />
            </div>
          </div>
        );
      }
    };

    const { shuStatusLocation } = this.state;
    const needDirection = isDrivelahGo ? showExactShuLocation : true;
    const hrefToGoogleMaps = modifiedGeoLocation
      ? `https://maps.google.com/?q=${modifiedGeoLocation.lat},${modifiedGeoLocation.lng}`
      : locationAddress
      ? `https://maps.google.com/?q=${encodeURIComponent(locationAddress)}`
      : null;

    let mapSectionPartUnverified = null;
    if (!txInVerificationProcess(transaction) && geolocation) {
      if (isCustomer) {
        if (
          currentCustomer.attributes &&
          currentCustomer.attributes.profile &&
          currentCustomer.attributes.profile.publicData &&
          currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
            'confirmed'
        ) {
          mapSectionPartUnverified = (
            <AddressLinkMaybe
              rootClassName={css.addressMobile}
              location={isProvider ? customerAddress : location}
              geolocation={modifiedGeoLocation}
              showAddress={stateData.showAddress}
              isProvider={isProvider}
              needDirection={needDirection}
              customAddress={
                isDrivelahGo ? (
                  <CarAddressData
                    showExactShuLocation={showExactShuLocation}
                    address={locationAddress}
                    shuStatusLocation={shuStatusLocation}
                    showFetchLiveLocationButton={showFetchLiveLocationButton}fetchCurrentAddress={e => {
                      e.preventDefault();
                      this.fetchCurrentGeoLocation(transactionId, currentListing);
                    }}
                    hrefToGoogleMaps={hrefToGoogleMaps}
                  />
                ) : null
              }
            />
          );
        }
      } else {
        mapSectionPartUnverified = (
          <AddressLinkMaybe
            rootClassName={css.addressMobile}
            location={isProvider ? customerAddress : location}
            geolocation={modifiedGeoLocation}
            showAddress={stateData.showAddress}
            isProvider={isProvider}
            needDirection={needDirection}
            customAddress={
              isDrivelahGo ? (
                <CarAddressData
                  showExactShuLocation={showExactShuLocation}
                  address={locationAddress}
                  shuStatusLocation={shuStatusLocation}
                  fetchCurrentAddress={e => {
                    e.preventDefault();
                    this.fetchCurrentGeoLocation(transactionId, currentListing);
                  }}
                  hrefToGoogleMaps={hrefToGoogleMaps}
                />
              ) : null
            }
          />
        );
      }
    }
    const mapSection = (
      <div className={!txInVerificationProcess(transaction) ? css.mapContainer : null}>
        {isCustomer &&
        !txInVerificationProcess(transaction) &&
        currentCustomer.attributes &&
        currentCustomer.attributes.profile &&
        currentCustomer.attributes.profile.publicData &&
        currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
          'confirmed' ? (
          <ListingMap
            geolocation={modifiedGeoLocation}
            listing={currentListing}
            showAddress={stateData.showAddress}
          />
        ) : null}
        {isProvider && !txInVerificationProcess(transaction) ? (
          <CustomerMap
            geolocation={modifiedGeoLocation}
            transaction={currentTransaction}
            showAddress={stateData.showAddress}
          />
        ) : null}
        {mapSectionPartUnverified}
      </div>
    );

    const chatSection = (
      <div className={isProvider ? css.chatContainerProvider : css.chatContainer}>
        <FeedSection
          onOpenShowImgModal={url => this.setState({ showImgModal: true, showImgModalUrl: url })}
          rootClassName={css.feedContainer}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={this.onOpenReviewModal}
          onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
          totalMessagePages={totalMessagePages}
          isFinishLongTerm={isFinishLongTerm}
        />
        <div id="headMessageForm" className={css.headMessageForm} />
        {showSendMessageForm ? (
          <SendMessageForm
            currentTransaction={currentTransaction}
            currentUser={currentUser}
            messages={messages}
            formId={this.sendMessageFormName}
            rootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            onFocus={this.onSendMessageFormFocus}
            onBlur={this.onSendMessageFormBlur}
            onSubmit={this.onMessageSubmit}
            submitButtonId={TRIP_SEND_MESSAGE_BUTTON_ID}
          />
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </div>
    );

    const photoSectionListCustomer = [
      {
        id: 0,
        title: 'Exterior of the car photos',
        description:
          'Document the condition of the car from the outside. Minimum 4 photos, one from each side.',
        data: 'exteriorPhotos',
      },
      {
        id: 1,
        title: 'Interior of the car photos',
        description: 'Document the condition of the car from the inside.',
        data: 'interiorPhotos',
      },
      {
        id: 2,
        title: 'Odometer Photo',
        description: 'Ensure odometer reading is clearly visible.',
        data: 'odometerPhotos',
      },
      {
        id: 3,
        title: 'Fuel Gauge Photo',
        description: 'Ensure fuel gauge value is clearly visible and readable.',
        data: 'fuelGaugePhotos',
      },
    ];

    const photoSectionListCustomerDLGO = [
      {
        id: 0,
        title: 'Exterior of the car photos',
        description:
          'Document the condition of the car from the outside. Minimum 4 photos, one from each side.',
        data: 'exteriorPhotos',
      },
      {
        id: 1,
        title: 'Selfie with ID card',
        description: 'Make sure your face and all the details on the ID card are clearly visible.',
        data: 'selfieWithId',
      },
      {
        id: 2,
        title: 'Interior of the car photos (optional)',
        description: 'Document the condition of the car from the inside.',
        data: 'interiorPhotos',
      },
      {
        id: 3,
        title: 'Odometer Photo',
        description: 'Ensure odometer reading is clearly visible.',
        data: 'odometerPhotos',
      },
      {
        id: 4,
        title: 'Fuel Gauge Photo',
        description: 'Ensure fuel gauge value is clearly visible and readable.',
        data: 'fuelGaugePhotos',
      },
    ];

    const photoSectionListProvider = [
      {
        id: 0,
        title: 'Upload photos (optional)',
        description:
          'You can choose to add photos to counter validate and avoid disputes at the end. Not mandatory.',
        data: 'pickupProviderPhoto',
        isEdit: true,
      },
    ];

    const photoSectionListPickUp = isCustomer
      ? isDrivelahGo
        ? [...photoSectionListCustomerDLGO]
        : [...photoSectionListCustomer]
      : [...photoSectionListProvider];

    const photoSectionListDropOff = isCustomer
      ? [...photoSectionListCustomer]
      : [...photoSectionListProvider];

    if (isCustomer && isDrivelahGo) {
      photoSectionListDropOff.push({
        id: 4,
        title: 'Fuel Receipt',
        description:
          'If you have topped up the fuel add receipts for that. If no receipts are added we will charge you for fuel on basis of the distance traveled.',
        data: 'fuelReceiptPhotos',
      });
    }

    const isPickUp =
      txIWaitingRequestPickup(currentTransaction) ||
      txIsBeingPickedUp(currentTransaction) ||
      txIWaitingRequestPickupLTF(currentTransaction) ||
      txIsBeingPickedUpLTF(currentTransaction) ||
      txIsReturnedBackBeforePickUp(currentTransaction);

    const isDropOff =
      txIsBeingDropOff(currentTransaction) ||
      txIWaitingRequestDropoff(currentTransaction) ||
      txIWaitingRequestDropoffLTL(currentTransaction) ||
      txIsBeingDropOffLTL(currentTransaction);

    const isPickUpInterior =
      txIsPickedUp(currentTransaction) ||
      txAfterCancelUpdateTrip(currentTransaction) ||
      (txIsPickedUpLTF(currentTransaction) && isDrivelahGo);

    const onNoteChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithNote = [...this.state[type]];
        photoWithNote.map(i => {
          i.note = note;
        });

        this.setState({ [type]: photoWithNote });
      }
    };

    const isShowPhotoSection = this.state.showPickUpSection || this.state.showDropOffSection;
    console.log('Last Transition', lastTransition);
    const isShowCancelLinkCustomer =
      lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
      lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED ||
      ((lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
        isTripYetToStart);
    const isShowAddonsLinkCustomer =
      (lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
      !isTimeToShowTransitionPickup;

    const isShuEnabledCar =
      currentTransaction.listing &&
      currentTransaction.listing.attributes.metadata &&
      currentTransaction.listing.attributes.metadata.isDrivelahGo;

    const allTransitions = get(currentTransaction, 'attributes.transitions');
    const isPickupRequested =
      allTransitions &&
      allTransitions.find(
        i =>
          i.transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
          i.transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE
      );

    const isBookingCompleted =
      transaction.booking &&
      moment()
        .tz(timeZone)
        .isSameOrAfter(
          moment(transaction.booking.attributes.displayEnd)
            .add(6, 'hours')
            .tz(timeZone)
        );

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {!isShowPhotoSection && (
              <PanelHeading
                panelHeadingState={stateData.headingState}
                transactionRole={transactionRole}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isCustomerBanned={isCustomerBanned}
                listingId={listingId}
                listingTitle={listingTitle}
                listingDeleted={listingDeleted}
              />
            )}

            {/* { isCustomer && currentCustomer && currentCustomer.id  && !(currentCustomer && currentCustomer.attributes  && currentCustomer.attributes.profile && currentCustomer.attributes.profile.metadata && currentCustomer.attributes.profile.metadata.DrivetogherDonated) &&  donateSection} */}

            {!isShowPhotoSection && (
              <BookingStatus
                lastBookingUpdate={lastBookingUpdate}
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                shouldShowPickUpSection={this.shouldShowPickUpSection}
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                transitionMaybe={transitionMaybe}
                timeZone={timeZone}
                isCustomer={isCustomer}
                isProvider={isProvider}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isLongTerm={isLongTerm}
                isDrivelahGo={isDrivelahGo}
                openModalReview={() => this.setState({ isReviewModalOpen: true })}
                isMobileLayout={isMobileLayout}
                openDisputeModal={() => this.setState({ showDisputeModal: true })}
                bookingUpdateTxs={bookingUpdateTxs}
                intl={intl}
              />
            )}

            {isCustomer && txInVerificationProcess(transaction) && (
              <div className={css.chatContainerProvider}>
                <AccountSettingDriverVerificationPage
                  pageName="tripPage"
                  haveNextTransit={haveNextTransit}
                  nextInteractableTransitions={nextInteractableTransitions}
                  isFromTripPage={true}
                  transaction={transaction}
                  transactionId={transactionId}
                  onTransit={onTransit}
                />
              </div>
            )}

            {/* {transactionTitle} */}
            {!isShowPhotoSection && isTabletLayout ? (
              <div className={css.mobileInfoContainer}>
                {!isShowPhotoSection && isTabletLayout ? (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomer}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                ) : null}

                <div className={css.grayLine} />

                {!isShowPhotoSection && isTabletLayout ? (
                  <PartnerInfo
                    currentUserRole={currentUserRole}
                    transaction={transaction}
                    otherUser={otherUser}
                    isMobileLayout={isMobileLayout}
                  />
                ) : null}
              </div>
            ) : null}

            {this.state.showPickUpSection && (
              <PickUpSection
                shouldShowPickUpSection={this.shouldShowPickUpSection}
                isCustomer={isCustomer}
                photoSectionList={photoSectionListPickUp}
                setActivePhotoSection={this.setActivePhotoSection}
                activePhotoSection={this.state.activePhotoSection}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                isPickUp={isPickUp || isPickUpInterior}
                currentUser={currentUser}
                setTripPhotos={data => {
                  this.setState(data);
                }}
                state={this.state}
                allTripPhotos={allTripPhotos}
                inputOdometerInvalid={inputOdometerInvalid}
                onNoteChange={onNoteChange}
                photoSectionListCustomer={photoSectionListCustomer}
                photoSectionListCustomerDLGO={photoSectionListCustomerDLGO}
                isDrivelahGo={isDrivelahGo}
                isPickedUp={txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
                isDropOff={isDropOff}
                isPickupRequested={isPickupRequested}
                interiorPhotoDlGo={interiorPhotoDlGo}
                odometerPhotoDlGo={odometerPhotoDlGo}
                fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              />
            )}

            {this.state.showDropOffSection && (
              <DropOffSection
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                isCustomer={isCustomer}
                photoSectionList={photoSectionListDropOff}
                setActivePhotoSection={this.setActivePhotoSection}
                activePhotoSection={this.state.activePhotoSection}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                isPickUp={isPickUp}
                isDropOff={isDropOff}
                currentUser={currentUser}
                setTripPhotos={data => {
                  this.setState(data);
                }}
                state={this.state}
                allTripPhotos={allTripPhotos}
                inputOdometerInvalid={inputOdometerInvalid}
                onNoteChange={onNoteChange}
                photoSectionListCustomer={photoSectionListCustomer}
              />
            )}

            {!isShowPhotoSection && !isCustomer && lastTransition !== TRANSITION_ENQUIRE && (
              <TripDetailsSection
                bookingUpdateTxs={bookingUpdateTxs}
                currentUser={currentUser}
                transaction={currentTransaction}
                parentTransaction={currentTransaction}
                transactionRole={transactionRole}
                viewport={this.props.viewport}
                transactionUpdateBooking={transactionUpdateBooking}
                intl={intl}
              />
            )}

            {!isShowPhotoSection && isProvider && chatSection}
            {!isShowPhotoSection &&
              lastTransition !== TRANSITION_ENQUIRE &&
              ((isDeliveryTransaction && isProvider) || isCustomer) &&
              mapSection}
            {!isShowPhotoSection &&
              isCustomer &&
              currentCustomer.attributes &&
              currentCustomer.attributes.profile &&
              currentCustomer.attributes.profile.publicData &&
              currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
                'confirmed' &&
              !txInVerificationProcess(transaction) && (
                <InfoSection
                  listingRegistrationNumber={listingRegistrationNumber}
                  fuelType={fuelType}
                  keyRules={keyRulesConfig}
                  selectedOptions={currentListing.attributes.publicData.keyRules}
                  isMobileLayout={isMobileLayout}
                  showAddress={stateData.showAddress}
                  listing={currentListing}
                  lastTransition={lastTransition}
                  isDrivelahGo={isDrivelahGo}
                />
              )}
            {/* Here the deposit section */}
            {showDepositTxSection && (
              <div className={css.infoSectionContainer}>
                <div className={css.infoCardContainer}>
                  <div className={css.bodyTextContainer}>
                    <h4 className={css.infoTitle}>{'Deposit'}</h4>
                    <p className={css.infotext}>
                      Your deposit was successfully charged and is on hold. You can request a refund
                      once your trip completes, or keep it on hold for next trip.
                    </p>
                    <a
                      className={css.learnMoreText}
                      onClick={() => history.push('/account/refundable-deposit')}
                    >
                      {' '}
                      <p className={css.infotext}>Learn More</p>{' '}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {!isShowPhotoSection &&
              isCustomer &&
              !(
                txIsUnverifiedRequested(transaction) ||
                txIsUnverifiedInstantRequested(transaction) ||
                txIsUnverifiedSubmittedInstant(transaction) ||
                txIsUnverifiedSubmitted(transaction)
              ) &&
              chatSection}

            {isTabletLayout && !isShowPhotoSection ? (
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
                isBookingCompleted={isBookingCompleted}
              />
            ) : null}
          </div>
          {/* {this.state.isDonateTransactionActive &&  !(currentUser && currentUser.attributes  && currentUser.attributes.profile && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.DrivetogherDonated) ? (
              <TripDonatePage
                  transaction={currentTransaction}
                  transactionId={transactionId}
                  currentUser={currentUser}
                  transactionRole={transactionRole}
                  providerName={authorDisplayName}
                  customerName={customerDisplayName}
                  isCustomerBanned={isCustomerBanned}
                  listingId={listingId}
                  listingTitle={listingTitle}
                  listingDeleted={listingDeleted}
                  isCustomer={isCustomer}
                  isProvider={isProvider}
                  handleGoBack={() => {
                    this.setState({
                      isDonateTransactionActive: false
                    })
                  }}
               />
          ) : (
            ''
           )} */}
          {!isShowPhotoSection && (
            <div className={css.asideDesktop}>
              <BookingInfoSection
                transaction={transaction}
                isCustomer={isCustomer}
                showAvatar={false}
                listing={currentListing}
                currentProvider={currentProvider}
                intl={intl}
              />
              <PartnerInfo
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                show={true}
                isMobileLayout={isMobileLayout}
              />
              {isDrivelahGo && !isShowPhotoSection && !isTabletLayout ? (
                <LockUnlockButtonMaybe
                  canShowButtons={haveNextTransit}
                  transaction={currentTransaction}
                  transitInProgress={transitInProgress}
                  transitError={transitError}
                  onTransit={onTransit}
                  currentUser={currentUser}
                  transitionNames={nextInteractableTransitions}
                  handleOpenStatusModal={this.handleOpenStatusModal}
                  tripPhotos={this.state.tripPhotos}
                  allTripPhotos={allTripPhotos}
                  ownListing={ownListing}
                  onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
                  isTabletLayout={isTabletLayout}
                />
              ) : null}
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
                isBookingCompleted={isBookingCompleted}
              />
            </div>
          )}
          {updateBookingButtons}
          {stateData.showSaleButtons || isShowPhotoSection ? (
            <ButtonsSection
              declineInProgress={declineInProgress}
              declineButtonName={declineButtonName}
              isPickupRequested={isPickupRequested}
              transaction={transaction}
              acceptInProgress={acceptInProgress}
              acceptSaleError={acceptSaleError}
              declineSaleError={declineSaleError}
              acceptButtonName={acceptButtonName}
              currentUser={currentUser}
              onDeclineSale={onDeclineSale}
              declineSaleGTM={declineSaleGTM}
              onAcceptSale={onAcceptSale}
              acceptSaleGTM={acceptSaleGTM}
              currentPhotos={this.state.currentPhotos}
              activePhotoSection={this.state.activePhotoSection}
              exteriorPhotos={this.state.exteriorPhotos}
              selfieWithId={this.state.selfieWithId}
              interiorPhotos={this.state.interiorPhotos}
              odometerPhotos={this.state.odometerPhotos}
              fuelGaugePhotos={this.state.fuelGaugePhotos}
              fuelReceiptPhotos={this.state.fuelReceiptPhotos}
              pickupProviderPhoto={this.state.pickupProviderPhoto}
              transitionMaybe={transitionMaybe}
              onTransit={onTransit}
              showSaleButtons={stateData.showSaleButtons}
              showPickUpSection={this.state.showPickUpSection}
              showDropOffSection={this.state.showDropOffSection}
              transitInProgress={transitInProgress}
              inputOdometerInvalid={inputOdometerInvalid}
              isShuEnabledCar={isShuEnabledCar}
              isProvider={isProvider}
              isCustomer={isCustomer}
              allTripPhotos={allTripPhotos}
              isPickUp={isPickUp}
              isDropOff={isDropOff}
              isDrivelahGo={isDrivelahGo}
              onPushEventGTMPickUpDropOff={this.onPushPickUpAndDropOffEventGTM}
              ownListing={ownListing}
              onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
              interiorPhotoDlGo={interiorPhotoDlGo}
              odometerPhotoDlGo={odometerPhotoDlGo}
              fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
              uploadInteriorSuccess={uploadInteriorSuccess}
              shouldShowPickUpSection={this.shouldShowPickUpSection}
              shouldShowDropOffSection={this.shouldShowDropOffSection}
            />
          ) : null}
          {isDrivelahGo && isShuEnabledCar && !isShowPhotoSection && isTabletLayout ? (
            <LockUnlockButtonMaybe
              canShowButtons={haveNextTransit}
              transaction={currentTransaction}
              transitInProgress={transitInProgress}
              transitError={transitError}
              onTransit={onTransit}
              currentUser={currentUser}
              transitionNames={nextInteractableTransitions}
              handleOpenStatusModal={this.handleOpenStatusModal}
              tripPhotos={this.state.tripPhotos}
              allTripPhotos={allTripPhotos}
              ownListing={ownListing}
              onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
              isTabletLayout={isTabletLayout}
            />
          ) : null}
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          buttonId={TRIP_PUBLISH_REVIEW_BUTTON_ID}
        />
        <ModalCancel
          cancelAllUpdateBookingTxsInProgress={cancelAllUpdateBookingTxsInProgress}
          rootClassName={actionButtonClasses}
          canShowButtons={haveNextCancelTransit}
          transaction={transactionForCancel}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={nextCancelTransition}
          openCancelPolicyModal={() => this.setState({ isCancelPolicyOpen: true })}
          isCustomer={isCustomer}
          isProvider={isProvider}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          showCancelModal={this.state.showCancelModal}
          onClose={() => this.setState({ showCancelModal: false })}
          isInstantBooking={isInstantBooking}
          onCancelTripGTM={this.onCancelTripGTM}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isAccepted={txIsAccepted(currentTransaction)}
          isLongTerm={isLongTerm}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
          onCancelAllUpdateBookings={onCancelAllUpdateBookings}
        />
        <ModalHelp
          onClose={() => this.setState({ showCancelHelpModal: false })}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isOpen={this.state.showCancelHelpModal}
        />
        <ModalCancelPolicy
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          transactionRole={transactionRole}
          showPolicyModal={this.state.isCancelPolicyOpen}
          onClose={() => this.setState({ isCancelPolicyOpen: false })}
          isLongTerm={isLongTerm}
        />
        <ModalReasonDispute
          isOpen={this.state.showDisputeModal}
          onClose={() => this.setState({ showDisputeModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          transaction={currentTransaction}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          onSendMessage={onSendMessage}
          reasonDispute={this.state.reasonDispute}
          reasonDisputePhotoUrls={this.state.reasonDisputePhotoUrls}
          onChoosePhoto={fileUrl => this.handleChooseDisputePhoto(fileUrl)}
          // tripPhotos={[
          //   ...allTripPhotos,
          //   ...(isShowUploadModal ? this.state.tripPhotos : []),
          // ].flat()}
          tripPhotos={[
            ...allTripPhotos,
            ...(isShowUploadModal ? this.state.tripPhotos : []),
          ].reduce((acc, val) => acc.concat(val), [])}
          onReasonDisputeChange={value => this.setState({ reasonDispute: value })}
          onPushDisputeEventGTM={this.onPushDisputeEventGTM}
          submitButtonId={TRIP_SUBMIT_DISPUTE_BUTTON_ID}
        />
      </div>
    );
  }

  stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned, isDelivery) {
    const lastTransition = transaction.attributes.lastTransition;
    if (txIsEnquired(transaction)) {
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: false,
      };
    } else if (txIsPaymentPending(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(transaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsWithdrawnByAdmin(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN_BY_ADMIN,
        showDetailCardHeadings: true,
      };
    } else if (txIsWithdrawn(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(transaction) || txIsRequestedLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)
    ) {
      return {
        headingState: HEADING_DECLINED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP)
    ) {
      return {
        headingState: HEADING_ACCEPTED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (
      txIWaitingRequestPickup(transaction) ||
      txIWaitingRequestPickupLTF(transaction) ||
      txIsBeingDropOff(transaction) ||
      txIsBeingDropOffLTL(transaction)
    ) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsDeclined(transaction) || txIsDeclinedLTF(transaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsCanceled(transaction)) {
      return {
        headingState: HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txHasBeenDelivered(transaction)) {
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedInstantRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedSubmitted(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else {
      return { headingState: 'unknown' };
    }
  }
}

TripPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitError: null,
  keyRulesConfig: config.custom.keyRules,
  nextTransitions: null,
};

TripPanelComponent.propTypes = {
  cancelAllUpdateBookingTxsInProgress: bool,
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  //transit props
  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape,

  keyRulesConfig: PropTypes.array,
};

const TripPanel = injectIntl(TripPanelComponent);

export default TripPanel;

function userIsVerified(transaction) {
  return (
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
    transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED
  );
}
