import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { ensureListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import isEqual from 'lodash/isEqual';
import css from './EditListingSubscriptionPanel.css';
import {
  createStripeSetupIntent,
  loadData,
  stripeCustomer,
} from '../../ducks/subscriptionPanel.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deletePaymentMethod, savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardSetup } from '../../ducks/stripe.duck';
import EditListingSubscriptionForm
  from '../../forms/EditListingSubscriptionForm/EditListingSubscriptionForm';


const { LatLng } = sdkTypes;

const EditListingSubscriptionPanel = memo(
  props => {
    const {
       className,
      rootClassName,
      listing,
      updateInProgress,
      submitButtonText,

    } = props;


    const classes = classNames(rootClassName || css.root, className);

    const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSubscriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingSubscriptionPanel.createSubscriptionTitle" />
  );

  return (
      <div className={classes}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <div className={css.titleSection}>
            <h1 className={css.title}>Subscription</h1>
            {/* <p>
              Set typical hours for days of the week your car is available and set availability for
              specific dates.
            </p> */}
          </div>
          <div className={css.form}>
            <EditListingSubscriptionForm
            updateInProgress={updateInProgress}
            saveActionMsg={submitButtonText}
          initValues={{
            plan: publicData.subscriptionPlan
          }}
          onSubmit={async values => {
            const { plan } = values
            const updatedValues = {
            publicData: {
              subscriptionPlan: plan
            },
          };
            return props.onSubmit(updatedValues)
          }}
          />
          </div>
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      prev,
      next
    )
);

const { func, object, string, bool } = PropTypes;

EditListingSubscriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingSubscriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};


const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { stripeCustomerFetched } = state.subscriptionPanel;

  const { handleCardSetupError } = state.stripe;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  initData: () => dispatch(loadData())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditListingSubscriptionPanel);

