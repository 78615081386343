import { currencyConfiguration } from './currency-config';
import defaultLocationSearches from './default-location-searches';
import * as custom from './marketplace-custom-config.js';
import { stripeCountryDetails, stripePublishableKey } from './stripe-config';

const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';
const isProd = process.env.REACT_APP_ENV === 'production'; // flag if its production env. TODO: prod build is always debug mode, we need to allow only build folder, not src folder or source maps in our prod.

const creditProgramId = process.env.REACT_APP_CREDITS_PROGRAM_ID;

// Get the server URL for API request
const apiUrl = process.env.REACT_APP_API_SERVER_URL;

const serverUrl = process.env.REACT_APP_API_SERVER_URL;

// Flag to restrict user from booking
const restrictUserFromBooking = false; //process.env.REACT_APP_RESTRICT_USER_FROM_BOOKING === 'true';

// const onfidoToken = "-ABk9sESWgKXkfoeQo80Ztz-zQlXXYr1";
const onfidoToken = process.env.ONFIDO_API_TOKEN;
// const onfidoBaseUrl = "https://api-test.drive-mate.com.au/api/onfido/webhook"
const onfidoBaseUrl = "https://api.onfido.com/v3"

const onfidoConfig = {
  token: onfidoToken,
  onfidoBaseUrl,
}

//Apple login options
const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
const appleCallback = `${serverUrl}/api/auth/apple/callback`;

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en';
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 0,
};

// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'one-for-all/release-1'; //default

const masterProcessAlias = 'one-for-all/release-1';

const updateBookingProcessName = 'update-booking-charging';
const updateBookingChargingProcessAlias = 'update-booking-charging/release-1';

const bookingProcessAliasPrivate = 'normal-customer-private-provider/with-instant-booking';
const bookingProcessAliasCommercial = 'normal-customer-commercial-provider/with-instant-booking';
const bookingProcessAliasYoungPrivate = 'young-customer-private-provider/with-instant-booking';
const bookingProcessAliasYoungCommercial =
  'young-customer-commercial-provider/with-instant-booking';
const additionalTransactionProcess = 'additional-addons-process/release-1';

const bookingProcessAliasVerificationProgram = 'verification/release-1';
const bookingProcessAliasAdmin = 'admin-preauthorized/sca-compatible';
const bookingProcessAliasName = 'admin-preauthorized';

const depositBookingProcessAliasAdmin = 'deposit/release-1';
const depositBookingProcessName = 'deposit';

const bookingProcessAliasDrivelahGoDeposit = 'drivelah-go-deposit/release-2';
const bookingProcessAliasCovidProgram = 'covid-program/release-1';
const bookingProcessAliasDonationProgram = 'donation-program/release-1';

const preauthenListingId = process.env.REACT_APP_ADMIN_PREAUTHEN_LISTING;
const depositListingId = process.env.REACT_APP_ADMIN_DEPOSIT_LISTING;
const preauthenListingIdWithDepositDiscount =
  process.env.REACT_APP_ADMIN_PREAUTHEN_LISTING_WITH_DEPOSIT_DISCOUNT;
const preauthenDrivelahGoDepositListingId = process.env.REACT_APP_DRIVELAH_GO_DEPOSIT_LISTING;
// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: translations will use different translation keys for night, day or unit
// depending on the value chosen.
const bookingUnitType = 'line-item/units';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
const enableAvailability = process.env.REACT_APP_AVAILABILITY_ENABLED === 'true';

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 90;

// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const sdkBaseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const sdkTransitVerbose = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';

const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 100;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

// // Currency formatting options.
// // See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
// //
// // TODO: Remove this and hide formating within the util/currency module
// const currencyConfig = {
//   style: 'currency',
//   currency,
//   currencyDisplay: 'symbol',
//   useGrouping: true,
//   minimumFractionDigits: 0,
//   maximumFractionDigits: 2,
// };

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'FI';
const addressRegion = 'Helsinki';
const postalCode = '2112';
const streetAddress = 'Bulevardi 14';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'Drive mate';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = '@drivelah';

// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = 'https://www.instagram.com/drive.mate';

// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = 'https://www.facebook.com/drivemate20';

// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
const googleTagId = process.env.REACT_APP_GTAG_ADS_CONVERSION;
const maps = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 50000,

    // Example location can be edited in the
    // `default-location-searches.js` file.
    defaults:
      process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true' ? defaultLocationSearches : [],

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    countryLimit: ['AU'],
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: true,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 1000,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 15,

    // Color of the circle on the Map component.
    circleColor: '#00a3ad',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: true,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    // url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),
    url:
      'https://user-images.githubusercontent.com/16278766/51592470-4ea07b00-1f22-11e9-96f2-9a2cdb76d2bb.png',

    // Dimensions of the marker image.
    width: 36,
    height: 36,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 30,
    anchorY: 32,
  },
};

const googleContacts = {
  apiKey: process.env.REACT_APP_GOOGLE_CONTACTS_API,
  clientId: process.env.REACT_APP_GOOGLE_CONTACTS_CLIENT_ID,
  scopes: 'https://www.googleapis.com/auth/contacts.readonly',
};

const appleAuthOptions = {
  clientId: appleClientId,
  scope: 'email name',
  redirectURI: appleCallback,
  state: 'state',
  nonce: 'nonce',
  usePopup: false
}

//API URL for intercom
const intercomAPIUrl = process.env.REACT_APP_INTERCOM_URL;
const intercomToken = process.env.REACT_APP_INTERCOM_ACCESS_TOKEN;

const useRelevance = process.env.REACT_APP_USE_RELEVANCE;
const relevanceApiKey = process.env.REACT_APP_RELEVANCE_API_KEY;
const relevanceEmail = process.env.REACT_APP_RELEVANCE_EMAIL;
const relevanceAbTestEnabled = process.env.REACT_APP_RELEVANCE_AB_TEST_ENABLED;
const calendlyWebPageUrl = process.env.REACT_APP_CALENDLY_BOOKING_URL;
// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  env,
  dev,
  isProd,
  apiUrl,
  intercomToken,
  intercomAPIUrl,
  restrictUserFromBooking,
  locale,
  bookingProcessAlias,
  masterProcessAlias,
  updateBookingProcessName,
  updateBookingChargingProcessAlias,
  bookingProcessAliasPrivate,
  bookingProcessAliasCommercial,
  bookingProcessAliasYoungCommercial,
  bookingProcessAliasYoungPrivate,
  bookingProcessAliasVerificationProgram,
  bookingProcessAliasAdmin,
  depositBookingProcessAliasAdmin,
  bookingProcessAliasName,
  depositBookingProcessName,
  additionalTransactionProcess,
  bookingUnitType,
  preauthenListingId,
  depositListingId,
  preauthenListingIdWithDepositDiscount,
  enableAvailability,
  dayCountAvailableForBooking,
  i18n,
  sdk: {
    clientId: sdkClientId,
    baseUrl: sdkBaseUrl,
    transitVerbose: sdkTransitVerbose,
    serverUrl,
  },
  sortSearchByDistance,
  currency,
  currencyConfig,
  listingMinimumPriceSubUnits,
  stripe: {
    publishableKey: stripePublishableKey,
    supportedCountries: stripeCountryDetails,
  },
  canonicalRootURL,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  siteTitle,
  siteFacebookPage,
  siteInstagramPage,
  creditProgramId,
  siteTwitterHandle,
  facebookAppId,
  googleTagId,
  sentryDsn,
  usingSSL,
  maps,
  custom,
  bookingProcessAliasDrivelahGoDeposit,
  bookingProcessAliasCovidProgram,
  preauthenDrivelahGoDepositListingId,
  bookingProcessAliasDonationProgram,
  googleContacts,
  relevance: {
    useRelevance,
    relevanceApiKey,
    relevanceAbTestEnabled,
    relevanceEmail,
  },
  calendlyWebPageUrl,
  onfidoConfig,
  appleAuthOptions
};

export default config;
