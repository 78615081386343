import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { composeValidators, nonEmptyArray } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Button, Form, ValidationError } from '../../components';

import css from './EditListingPhotosForm.css';
import EditListingPhotosPicturesGallery from './EditListingPhotosPicturesGallery';
import PhotosModal from './PhotosModal';
import frontView from '../../assets/updatelisting/front-view.png';
import threeQuartersView from '../../assets/updatelisting/three-quarters-view.png';
import sideViewRight from '../../assets/updatelisting/side-view-right.png';
import sideViewLeft from '../../assets/updatelisting/side-view-left.png';

const ACCEPT_IMAGES = 'image/*';
const MORE_PICTURES = 99999;

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      isModalOpen: false,
      imageLoop: [],
      uploadedImageIndex: 0,
      isImageUploadInprogress: false
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  componentDidMount() {
    this.refreshImageLoopObject();
  }
  componentDidUpdate(prevProps, nextProps) {
    const { uploadedImageIndex } = this.state;
    const { images } = this.props;
    if (prevProps.images.length !== images.length) {
      // this.refreshImageLoopObject(uploadedImageIndex);
    }
  }

  onImageUploadHandler(file, index) {
    if (file) {
      this.setState({ imageUploadRequested: true, isImageUploadInprogress: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          const getTheUploaderIndex = index ? index.split('addImage')[1] : MORE_PICTURES;
          this.setState({
            imageUploadRequested: false,
            isImageUploadInprogress: false,
            uploadedImageIndex: parseInt(getTheUploaderIndex),
          });
          this.pushImagesToSelectedIndex(parseInt(getTheUploaderIndex));
          // this.refreshImageLoopObject(parseInt(getTheUploaderIndex));
        })
        .catch(e => {
          console.log('e', e);
          this.setState({ imageUploadRequested: false, isImageUploadInprogress: false });
        });
    }
  }

  pushImagesToSelectedIndex = index => {
    const { imageLoop } = this.state;
    const { images } = this.props;
    if (index === MORE_PICTURES) {
      const addAdditionalObject = {
        images: images[images.length - 1],
        imageIndex: imageLoop.length,
        imageAngle: 'Additional images',
        placeHolderImage: threeQuartersView,
      };
      imageLoop.push(addAdditionalObject);
    } else {
      imageLoop[index].images = images.length > 1 ? images[images.length - 1] : images[0];
    }
    this.setState({ imageLoop });
  };

  openModal = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  changeModalVisiblity = value => {
    this.setState({
      isModalOpen: value,
    });
  };

  removeSelectedImage = image => {
    const { imageLoop } = this.state;
    let removeFilter;
    if (image.file) {
      removeFilter =
        imageLoop &&
        imageLoop.length > 0 &&
        imageLoop.filter(
          item => {
            if (item.images.file) {
              return item.images.imageId.uuid !== image.imageId.uuid;
            }
            return item;
          }
          // item &&
          // item.images &&
          // item.images.imageId &&
          // item.images.imageId.uuid !== image.imageId.uuid
        );
    } else {
      removeFilter =
        imageLoop &&
        imageLoop.length > 0 &&
        imageLoop.filter(item => item.images.id.uuid !== image.id.uuid);
    }
    this.setState({
      imageLoop: removeFilter,
    });
  };

  refreshImageLoopObject = uploadedImageIndex => {
    const { imageLoop } = this.state;
    const { images } = this.props;
    let imageLoopObj = [];
    // if (images && images.length > 0) {
    const loopLength = Math.max(images.length || 0, 4);
    for (let i = 0; i < loopLength; i++) {
      if (i > 3) {
        const addAdditionalObject = {
          images: images[i],
          imageAngle: 'Additional images',
          placeHolderImage: threeQuartersView,
        };
        imageLoopObj.push(addAdditionalObject);
      } else {
        const addAdditionalObject = {
          index: i,
          images: images[i],
          imageAngle:
            i === 0
              ? '3 Quarters View *'
              : i === 1
              ? 'Front View *'
              : i === 2
              ? 'Side View *'
              : i === 3
              ? 'Side View *'
              : 'Additional images',
          placeHolderImage:
            i === 0
              ? threeQuartersView
              : i === 1
              ? frontView
              : i === 2
              ? sideViewRight
              : i === 3
              ? sideViewLeft
              : threeQuartersView,
        };
        imageLoopObj.push(addAdditionalObject);
      }
    }
    // }
    this.setState({
      imageLoop: imageLoopObj,
    });
  };

  getImageArray = images => {
    const imagesArray = [];
    for (let i = 0; i < images.length; i++) {
      imagesArray.push(images[i].images);
    }
    return imagesArray;
  };

  render() {
    const { imageLoop, isImageUploadInprogress } = this.state;
    console.log("isImageUploadInprogress", isImageUploadInprogress);
    const { images } = this.props;
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        isImageUploadInprogress={isImageUploadInprogress}
        initialValues={{ images: this.getImageArray(imageLoop) }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            onSkip,
            images,
            isImageUploadInprogress,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            submitButtonId,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const minImagesRequired = (images.length || 0) >= 4
          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          // const submitReady = updated || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || imageUploadRequested || ready ||
            !minImagesRequired;

          const classes = classNames(css.root, className);

          return (
            <>
              <Form
                className={classes}
                onSubmit={e => {
                  this.submittedImages = images;
                  handleSubmit(e);
                }}
              >
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                  </p>
                ) : null}
                <div className={css.displayInlineContainer}>
                  <div className={css.flexContent}>
                    {imageLoop &&
                      imageLoop.length > 0 &&
                      imageLoop.map((image, index) => (
                        <EditListingPhotosPicturesGallery
                          chooseImageText={chooseImageText}
                          onRemoveImage={image => {
                            onRemoveImage(image);
                          }}
                          removeSelectedImage={this.removeSelectedImage}
                          image={image.images}
                          isImageUploadInprogress={isImageUploadInprogress}
                          placeHolderImage={image.placeHolderImage}
                          imageAngle={image.imageAngle}
                          intl={intl}
                          imageUploadRequested={imageUploadRequested}
                          onImageUploadHandler={onImageUploadHandler}
                          imageRequiredMessage={imageRequiredMessage}
                          uploadImageFailed={uploadImageFailed}
                          form={form}
                          index={index}
                        />
                      ))}
                  </div>
                  <div className={css.flexBtns}>
                    <div className={classNames(css.column, css.column6, css.addMoreButton)}>
                      <div className={css.viewUploadPhotos}>
                        <Field
                          id="addImage"
                          name="addImage"
                          accept={ACCEPT_IMAGES}
                          form={null}
                          label={chooseImageText}
                          type="file"
                          disabled={imageUploadRequested}
                        >
                          {fieldprops => {
                            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                            const { name, type } = input;
                            const onChange = e => {
                              const file = e.target.files[0];
                              form.change(`addImage`, file);
                              form.blur(`addImage`);
                              onImageUploadHandler(file);
                            };
                            const inputProps = { accept, id: name, name, onChange, type };
                            return (
                              <div className={css.addImageWrapper}>
                                <div className={css.aspectRatioWrapper}>
                                  {fieldDisabled ? null : (
                                    <input {...inputProps} className={css.addImageInput} />
                                  )}
                                  <label
                                    htmlFor={name}
                                    className={classNames(
                                      css.addImage,
                                      css.addMoreImage,
                                      images && images.length > 3 ? css.enableAddMoreImage : ''
                                    )}
                                  >
                                    Add more photos
                                  </label>
                                </div>
                              </div>
                            );
                          }}
                        </Field>

                        <Field
                          component={props => {
                            const { input, meta } = props;
                            return (
                              <div className={css.imageRequiredWrapper}>
                                <input {...input} />
                                <ValidationError fieldMeta={meta} />
                              </div>
                            );
                          }}
                          name="images"
                          type="hidden"
                          validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                        />
                      </div>
                    </div>
                    <div className={classNames(css.column, css.column6)}>
                      <button className={css.viewUploadPhotos} onClick={this.openModal}>
                        View sample photos
                      </button>
                    </div>
                  </div>
                </div>
                {publishListingFailed}
                {showListingFailed}
                <div className={css.stickyButtons}>
                  <div className={css.stickButtonsContainer}>
                    <div className={css.stickButtonsDescription}>
                      <button onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSkip()
                      }} className={css.linkedButton}>Skip &amp; Add pictures later</button>
                    </div>
                    <div className={css.stickButton}>
                      <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                        id={submitButtonId}
                      >
                        {/* {saveActionMsg} */}
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
              {this.state.isModalOpen && (
                <PhotosModal
                  isModalOpen={this.state.isModalOpen}
                  changeModalVisiblity={this.changeModalVisiblity}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
