import React from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FieldTextInput, Form, IconEnquiry, NamedLink, PrimaryButton } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import moment from 'moment';
import EnquiryFieldEndDateAndTimeInput from '../BookingTimeForm/EnquiryFieldEndDateAndTimeInput';
import EnquiryFieldStartDateAndTimeInput
  from '../BookingTimeForm/EnquiryFieldStartDateAndTimeInput';

import css from './EnquiryForm.css';

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    rawInitialValues={props.initialValues}
    render={fieldRenderProps => {
      const {
        form,
        pristine,
        handleSubmit,
        intl,
        isOwnListing,
        submitButtonWrapperClassName,
        values,
        unitPrice,
        unitType,
        onFetchTimeSlots,
        currentUser,
        listing,
        rootClassName,
        className,
        formId,
        inProgress,
        listingTitle,
        initialValues: rawInitialValues,
        updateBooking,
        authorDisplayName,
        transaction,
        sendEnquiryError,
        buttonId,
        monthlyTimeSlots,
        localTimeZone,
        isLongTerm,
        timeSlots,
      } = fieldRenderProps;
      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );

      const TODAY = new Date();
      const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
      const startDatePlaceholder = intl.formatDate(TODAY, dateFormattingOptions);
      const endDatePlaceholder =intl.formatDate(TODAY, dateFormattingOptions);
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;

      const verifiedGuest = currentUser && currentUser.identityStatus && currentUser.identityStatus.guestVerified;
      const underVerifiedGuest = currentUser && currentUser.identityStatus && currentUser.identityStatus.guestUnderVerify;
      console.log("<<<<verifiedGuest",verifiedGuest)
      const submitDisabled =  !verifiedGuest;

      const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

      const bookingStartLabel = intl.formatMessage({
        id: 'BookingTimeForm.bookingStartTitle',
      });
      const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

      const startDateInputProps = {
        label: bookingStartLabel,
        placeholderText: startDatePlaceholder,
      };
      const endDateInputProps = {
        label: bookingEndLabel,
        placeholderText: endDatePlaceholder,
      };

      const listingId = props.listing && props.listing.id.uuid;

      const { publicData = {} } = listing && listing.attributes || {};
      const hourlyAvailability = publicData && publicData.hourlyAvailability;

      const beyond6Months = day => {
        const limit = moment().add(6, 'months');
        return !limit.isSameOrAfter(day);
      };

      const dateInputProps = {
        startDateInputProps,
        endDateInputProps,
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle }} />
          </h2>
{console.log("<<<<<DEBUG7!")}
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
               <EnquiryFieldStartDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    startDatePlaceholder={startDatePlaceholder}
                    endDatePlaceholder={endDatePlaceholder}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : beyond6Months}
                    // location={location}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
                  <EnquiryFieldEndDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingEndDates}
                    listingId={listingId}
                    startDatePlaceholder={startDatePlaceholder}
                    endDatePlaceholder={endDatePlaceholder}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    timeSlots={timeSlots}
                    localTimeZone={localTimeZone}
                    isOutsideRange={isLongTerm ? undefined : beyond6Months}
                    // location={location}
                    hourlyAvailability={hourlyAvailability}
                    updateBooking={updateBooking}
                    initialValues={rawInitialValues}
                    transaction={transaction}
                  />
          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
              // onMonthChanged, timeZone, localTimeZone, location
            ) : null}
            {console.log("<<<<TIMEZONE",timeZone,localTimeZone)}


            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled} id={buttonId}>
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton>

            {currentUser && currentUser.id && !underVerifiedGuest && !verifiedGuest &&
              <div className={css.notVerifiedGuestWarning}>
                <p><FormattedMessage id="BookingDatesForm.notVerifiedGuestWarning" /></p>
                <NamedLink
                  name="AccountSettingDriverVerificationPage"
                >
                  <FormattedMessage id="BookingDatesForm.notVerifiedGuestWarningLink" />
                </NamedLink>
              </div>
            }
          </div>
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  buttonId: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
