import config from '../config';

const apiUrl = config.apiUrl;

export const fetchShuLastLocation = (tId, fleetId) => {
  return fetch(apiUrl + '/api/shu-last-location', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tId,
      fleetId,
    }),
  }).then(res => res.json());
};
