import React from 'react';
import css from './BrandingPage.css';
import { FormattedMessage } from 'react-intl';
import shareIcon from '../../assets/share1.svg';
import shareIcon2 from '../../assets/share2.svg';
import shareIcon3 from '../../assets/share3.svg';


const Carsharing = () => {
  return (
    <div className={css.sectionBrandHero}>
      <div className={css.SectionHeroBrandingBg}>
        <div className={css.BrandingTitle}>
          <FormattedMessage id="CarSharing.SectionHero.title" />
        </div>
        </div>
        <div className={css.cards}>
            <div className={css.card}>
                <div className={css.stepWrapper}><div className={css.step}>1</div></div>
                <img src={shareIcon} alt="" />
                <h3 className={css.cardTitle}>Create a free listing</h3>
                <p className={css.cardPara}>Let’s get cracking! Register vehicles for rental in minutes and join your local car catalogue with zero sign up fees. Await your first booking and get excited to start making some cash!</p>
            </div>

            <div className={css.card}>
            <div className={css.stepWrapper}><div className={css.step}>2</div></div>
                <img src={shareIcon2} alt="" />
                <h3 className={css.cardTitle}>Accept a booking</h3>
                <p className={css.cardPara}>You’re in full control of your pricing, availability, accepting or declining. And we have you covered with our insurance policy.</p>
            </div>

            <div className={css.card}>
            <div className={css.stepWrapper}><div className={css.step}>3</div></div>
                <img src={shareIcon3} alt="" />
                <h3 className={css.cardTitle}>Get Paid</h3>
                <p className={css.cardPara}>Let your car work for you. Within 3-5 days of the trip, you will receive the payment directly into your bank account.</p>
            </div>
        </div>
    </div>
  );
};

export default Carsharing;
