import React, { memo, useMemo } from 'react';
import moment from 'moment';
import { string } from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  Logo,
  NamedLink,
} from '../../components';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_ABOUTUS,
  EVENT_BROWSE_FLEXPLUS,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_POLICIES,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
  EVENT_BROWSE_TRUSTANDSAFE,
} from '../../util/gtm/gtmConstants';
import LogoImage from '../../assets/logos/drive-mate-footer-logo.png';
import css from './Footer.css';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const Footer = memo(
  props => {
    const { rootClassName, className, intl, bookingPanelMobileHeight = 0 } = props;
    const socialMediaLinks = useMemo(() => renderSocialMediaLinks(intl), [intl]);
    const classes = classNames(rootClassName || css.root, className);
    const pushGTMBrowse = event => {
      pushGTMBrowseEvent({
        props,
        event,
      });
    };
    const currentYear = moment(new Date()).format('YYYY');
    return (
      <div className={classes}>
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.someLiksMobile}>{socialMediaLinks}</div>
            <div className={css.links}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="desktop" className={css.logo} logoSrc={LogoImage} />
                </NamedLink>
                <div className={css.organizationInfo}>
                  <p className={css.organizationDescription}>
                    <FormattedMessage id="Footer.organizationDescription" />
                  </p>
                  <p className={css.FooterAddress}>
                    <FormattedMessage id="Footer.Address" />
                  </p>
                  <p className={css.FooterAddress}>
                  <a href="tel:+61341602205" className={css.FooterAddress}>Call us: <span style={{textDecoration: "underline"}}>+61341602205</span></a>
                  </p>
                  <p className={css.organizationCopyright}>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
                    </NamedLink>
                  </p>
                </div>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  <li className={css.listItem}></li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAGuestPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
                    >
                      <FormattedMessage id="Footer.rentACar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAHostPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
                    >
                      <FormattedMessage id="Footer.shareYourCar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="HowItWorkPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
                    >
                      <FormattedMessage id="Footer.howItWork" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="LandingPageFlex"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_FLEXPLUS)}
                    >
                      <FormattedMessage id="Footer.Flex+" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="TrustAndSafetyPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      <FormattedMessage id="Footer.trustAndSafety" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="LandingPageGo"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      Drive mate Go™
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="AboutPage"
                      className={css.link}
                      onClick={() => pushGTMBrowse(EVENT_BROWSE_ABOUTUS)}
                    >
                    <FormattedMessage id="Footer.aboutUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem} onClick={() => pushGTMBrowse(EVENT_BROWSE_POLICIES)}>
                    <ExternalLink
                      href={`https://help.${DOMAIN_HELP_URL}/kb/en/our-policies-101403`}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.policies" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={`https://help.${DOMAIN_HELP_URL}/`} className={css.link}>
                      <FormattedMessage id="Footer.help" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ContactUsPage" className={css.link}>
                      <FormattedMessage id="Footer.contactUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink className={css.link} href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/frequently-asked-questions-nBU4CC7PL7/Steps/750993`}>
                      FAQ
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div className={css.ourPartners}>Our partners</div>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.allianz.com/en.html'} className={css.link}>
                      Allianz
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.gtins.com.au/'} className={css.link}>
                      GT Insurance
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="RentalAgreementPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.rentalAgreement" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={css.copyrightAndTermsMobile}
              style={
                bookingPanelMobileHeight
                  ? { marginBottom: (bookingPanelMobileHeight * 40) / 100 }
                  : {}
              }
            >
              <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
              </NamedLink>
              <div className={css.tosAndPrivacyMobile}>
                <NamedLink name="TermsOfServicePage" className={css.terms}>
                  <FormattedMessage id="Footer.terms" />
                </NamedLink>
                <NamedLink name="RentalAgreementPage" className={css.rental}>
                  <FormattedMessage id="Footer.rentalAgreement" />
                </NamedLink>
                <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </div>
            </div>
            <div >
              <p className={css.FooterAddressMobile} >
                <FormattedMessage id="Footer.Address" />
              </p>
              <p className={css.FooterAddressMobile}>
                  <a style={{color: "#b2b2b2"}} href="tel:+61341602205" className={css.FooterAddress}>Call us: <span style={{textDecoration: "underline"}}>+61341602205</span></a>
                  </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev.bookingPanelMobileHeight, next.bookingPanelMobileHeight)
);

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
