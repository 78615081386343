import React, { memo } from 'react';
import css from './HostPromoLandingPage.css';
import classNames from 'classnames';

const SectionSharingCarsSimply = memo(() => {
  return (
    <div className={css.simplySharingCars}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column12}>
            <div className={css.sectionTitle}>Car sharing made simple</div>
          </div>
        </div>
        <div className={classNames(css.landingRow, css.blockList)}>
          <div className={css.column4}>
            <div className={css.columnTitle}>Create a listing for free</div>
            <p>
              Let’s get cracking! Register vehicles for rental in minutes and join your local car
              catalogue with zero sign up fees. Await your first booking and get excited to start
              making some cash!
            </p>
          </div>
          <div className={css.column4}>
            <div className={css.columnTitle}>Accept a booking</div>
            <p>
              You’re in full control of your pricing, availability, accepting or declining. And we
              have you covered with our insurance policy.
            </p>
          </div>
          <div className={css.column4}>
            <div className={css.columnTitle}>Get paid</div>
            <p>
              Let your car work for you. Within 3-5 days of the trip, you will receive the payment
              directly into your bank account.
            </p>
          </div>
        </div>
        {/* <div className={css.landingRow}>
          <div className={classNames(css.column12, css.textCenter)}>
            <button className={css.sectionButton}>List your car</button>
          </div>
        </div> */}
      </div>
    </div>
  );
});

export default SectionSharingCarsSimply;
