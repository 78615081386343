import config from '../config';
import { types as sdkTypes } from './sdkLoader';

const { UUID } = sdkTypes;
const apiUrl = config.apiUrl;

const UPDATE_USER_REFERRAL_CODE = "events/UPDATE_USER_REFERRAL_CODE";

export const checkIfReferralCode = (code) => {
  return !!code.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
};

export const checkIfReferralCodeIsValid = (code) => {
  // const testHost = 'http://localhost:3001';
  return fetch(apiUrl + `/api/v2/lightrail/check-referral-code/${code}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(res => {
    if (res.ok) {
      return res.json();
    }
    return res.json().then((error) => {
      return error;
    });
  })
}

export const updateReferralCodeData = data => {
  const { referralCode, codeValid, userId, referrerName } = data;
  if (typeof window === 'undefined')
    return;

  // const testHost = 'http://localhost:3001';
  fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      referralCode,
      referrerName,
      codeValid,
      createdAt: new Date().toISOString(),
      eventType: UPDATE_USER_REFERRAL_CODE,
    }),
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  }).then(response => {
  }).catch(error => {
    console.error(error);
    throw {
      name: 'Invalid sign up / referral code',
      message: 'Invalid sign up / referral code',
    };
  })
}

//This API checks the code is a signUpcode, referralCode or voucherCode and validates it
export const checkSignUpCode = (code) => {
  return fetch(apiUrl + `/api/v2/lightrail/check-sign-up-code/${code}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw res.blob();
    });
};
