import React from 'react';
import css from './GrabHostYourCarLandingPage.css';
// import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import bannerLogo from '../../assets/grabBanner/banner-logo.png';
import bannerLogoMobile from '../../assets/grabBanner/banner-logo-mobile.png';
import grabLogo from '../../assets/grabBanner/grab-rentals-desktop.png';
import grabLogoWhite from '../../assets/grabBanner/grab-rentals-mobile.png';
import bannerBackground from '../../assets/grabBanner/banner-background.png';
import approveBlueIcon from '../../assets/grabBanner/approval-symbol-in-badge-blue@2x.png';
import saveMoneyIcon from '../../assets/grabBanner/noun_Save Money_776848@2x.png';
import easyAccessIcon from '../../assets/grabBanner/noun_easy_3428320@2x.png';
import approveBlueIconMobile from '../../assets/grabBanner/approval-symbol-in-badgeM@2x.png';
import saveMoneyIconMobile from '../../assets/grabBanner/noun_Save Money_776848M@2x.png';
import easyAccessIconMobile from '../../assets/grabBanner/1024px-Dollar_sign_in_circle.svg@2x.png';

const GrabIntroBanner = ({ isMobileLayout, handleSignupRedirect }, ...props) => {
  return (
    <div className={css.bannerIntroSection}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column6}>
            <div className={css.bannerIntroContent}>
              <div className={css.bannerLogoBlock}>
                {!isMobileLayout && <img src={bannerLogo} alt="" />}
              </div>
              <h1>Is your car sitting idle?</h1>
              <p>Rent out your car, and make some extra money, only with Drive mate</p>
              <ul className={css.bannerListing}>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={approveBlueIconMobile} alt="" />
                    ) : (
                      <img src={approveBlueIcon} alt="" />
                    )}
                  </span>
                  Approved by{' '}
                  <span className={css.contentIcon}>
                    {isMobileLayout ? (
                      <img src={grabLogoWhite} alt="" />
                    ) : (
                      <img src={grabLogo} alt="" />
                    )}
                  </span>
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={saveMoneyIconMobile} alt="" />
                    ) : (
                      <img src={saveMoneyIcon} alt="" />
                    )}
                  </span>
                  Earn passive income during your off days
                </li>
                <li>
                  <span className={css.pointIcons}>
                    {isMobileLayout ? (
                      <img src={easyAccessIconMobile} alt="" />
                    ) : (
                      <img src={easyAccessIcon} alt="" />
                    )}
                  </span>
                  Sign up now to get $100 Drive mate credits
                </li>
              </ul>
              <button onClick={e => handleSignupRedirect(e)} className={css.bannerButton}>
                Start earning now
              </button>
              <div className={css.mobileBannerLogo}>
                {isMobileLayout && <img src={bannerLogoMobile} alt="" />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.bannerIntroImageSection}>
        <img src={bannerBackground} alt="" />
      </div>
    </div>
  );
};

export default GrabIntroBanner;
