import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { EditListingInsuranceForm } from '../../forms';
import config from '../../config';

import css from './EditListingInsurancePanel.css';


class EditListingInsurancePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizationDocumentUrl: null,
      insuranceDocumentUrl: null,
     };
     this.setAuthorizationDocumentUrl = this.setAuthorizationDocumentUrl.bind(this);
     this.setInsuranceDocumentUrl = this.setInsuranceDocumentUrl.bind(this);
  }

  setAuthorizationDocumentUrl(data) {
    this.setState({
      authorizationDocumentUrl: data
    });
  }

  setInsuranceDocumentUrl(data) {
    this.setState({
      insuranceDocumentUrl: data
    });
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      isNewListingFlow,
      submitButtonId,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingInsurancePanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingInsurancePanel.createListingTitle" />
    );
    const hasAuthorizationDocumentUrl = Array.isArray(publicData.authorizationDocumentUrl) && publicData.authorizationDocumentUrl.length;
    const hasInsuranceDocumentUrl = Array.isArray(publicData.insuranceDocumentUrl) && publicData.insuranceDocumentUrl.length;
    const insuranceLayout = isNewListingFlow ? (
      <div className={classes}>
        {/* <h1 className={css.title}>{panelTitle}</h1> */}
        <div className={css.titleSection}>
          <h1 className={css.title}>Insurance</h1>
          <p>
            What kind of insurance does your car currently have? This will help us provide a cover
            to our guests. dadasdasdsd
          </p>
        </div>
        <EditListingInsuranceForm
          isNewListingFlow={isNewListingFlow}
          className={css.form}
          publicData={publicData}
          hasAuthorizationDocumentUrl={hasAuthorizationDocumentUrl}
          hasInsuranceDocumentUrl={hasInsuranceDocumentUrl}
          initialValues={{ insurance: publicData.insurance, isPHV: publicData.isPHV ? 'yes' : 'no', companyOwned: hasAuthorizationDocumentUrl ? 'yes' : 'no'}}
          onSubmit={(values)  => {
            const { insurance = '', companyOwned = '', isPHV } = values;
            const insuranceDocumentUrl = insurance === "commercial" ? this.state.insuranceDocumentUrl : [];
            const authorizationDocumentUrl = insurance === "commercial" && companyOwned === 'yes' ? this.state.authorizationDocumentUrl : [];

            const updateValues = {
              publicData: {
                insurance,
                companyOwned,
                insuranceDocumentUrl,
                authorizationDocumentUrl,
                isPHV: isPHV === 'yes' ? true : false
              },
            };
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          currentUser={this.props.currentUser}
          insurances={config.custom.insurances}
          yesNo={config.custom.yesNo}
          privateInsurerList={config.custom.privateInsurerList}
          insuranceDocumentUrl={publicData.insuranceDocumentUrl}
          authorizationDocumentUrl={publicData.authorizationDocumentUrl}
          setAuthorizationDocumentUrl={this.setAuthorizationDocumentUrl}
          setInsuranceDocumentUrl={this.setInsuranceDocumentUrl}
          submitButtonId={submitButtonId}
        />
      </div>
    ) : (<div className={classes}>
        <div className={css.titleSection}>
          <h1 className={css.title}>Insurance</h1>
          <p>
            What kind of insurance does your car currently have? This will help us provide a cover
            to our guests.
          </p>
        </div>
        <div className={css.form}>
          <p>
            <strong>NOTE:</strong> If you want to update this information, you must contact Drive mate admin
          </p>
        </div>
    </div>);

    return insuranceLayout;
  }
}

EditListingInsurancePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingInsurancePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isNewListingFlow: bool.isRequired,
  submitButtonId: string,
};

export default EditListingInsurancePanel;
