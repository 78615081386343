import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes, { array, arrayOf, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SectionRulesMaybe from '../../containers/ListingPage/SectionRulesMaybe';
import SmallPartnerInfo from './SmallPartnerInfo';
import SectionUploadCarStatus from './SectionUploadCarStatus';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  getUserTxRole,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  txHasBeenDelivered,
  txHasFirstReviewedByCustomer,
  txHasFirstReviewedByProvider,
  txIsAccepted,
  txIsAcceptedAfterOneHour,
  txIsAcceptedNonRefundable,
  txIsAcceptedRefundable,
  txIsAfterConfirmPickUp,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsCanceledByHost,
  txIsCompleted,
  txIsDeclined,
  txIsEnquired,
  txIsExpired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txIsReviewed,
  txIsWithdrawn,
  txIsWithdrawnByAdmin,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
} from '../../util/transaction';
import {
  txIsBeingPickedUpLTF,
  txIsDeclinedLTF,
  txIsExpiredLTF,
  txIsRequestedLTF,
  txIWaitingRequestPickupLTF,
} from '../../util/transactionLongTermFirst';
import { propTypes } from '../../util/types';
import {
  checkOwnListing,
  ensureListing,
  ensureTransaction,
  ensureUser,
  getBookingConfig,
  listingIsLongTermRental,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { convertMoneyToNumber } from '../../util/currency';
import {
  BookingPanel,
  InsurancePanelNew,
  Modal,
  ModalCancel,
  ModalCancelPolicy,
  NamedLink,
  PrimaryButton,
  RentalAgreement,
  ResponsiveImage,
  ReviewModal,
  TransactionIdentityVerification,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  CANCEL_BOOKING_BUTTON_ID,
  EVENT_BOOK_REQUEST_WITHDRAWN_GUEST,
  EVENT_SEARCH_READ_INSURANCE,
  EVENT_TRIP_DISPUTED_GUEST,
  EVENT_TRIP_DISPUTED_HOST,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_DROPOFF_REQUEST_RECEIVED,
  EVENT_TRIP_DROPOFF_REQUEST_SENT,
  EVENT_TRIP_GUEST_CANCEL_GUEST,
  EVENT_TRIP_GUEST_REVIEW_GUEST,
  EVENT_TRIP_GUEST_REVIEW_HOST,
  EVENT_TRIP_HOST_CANCEL_HOST,
  EVENT_TRIP_HOST_REVIEW_GUEST,
  EVENT_TRIP_HOST_REVIEW_HOST,
  EVENT_TRIP_MESSAGE_SENT_GUEST,
  EVENT_TRIP_MESSAGE_SENT_HOST,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_PICKUP_REQUEST_RECEIVED,
  EVENT_TRIP_PICKUP_REQUEST_SENT,
  REQUEST_TO_BOOK_BUTTON_ID,
  SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST,
  SERVER_EVENT_TRIP_GUEST_CANCEL_HOST,
  SERVER_EVENT_TRIP_HOST_CANCEL_GUEST,
  TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
  TRIP_PUBLISH_REVIEW_BUTTON_ID,
  TRIP_SEND_MESSAGE_BUTTON_ID,
  TRIP_SUBMIT_DISPUTE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import {
  initiateEventFromListing,
  initiateEventFromTransaction,
  pushGTMTripEvent,
} from '../../util/gtm/gtmHelpers';

import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import BreakdownLongTermMaybe from './BreakdownLongTermMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import UpdateBookingButtonsMaybe from '../TripPanel/UpdateBookingButtonsMaybe';
import ModalReasonDispute from './ModalReasonDispute';
import { ShowImgModal } from './ShowImgModal';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_ACCEPTED_UPDATE_BOOKING,
  HEADING_CANCELED,
  HEADING_DECLINED,
  HEADING_DECLINED_UPDATE_BOOKING,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_PAYMENT_EXPIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_REQUEST_UPDATE_BOOKING,
  HEADING_REQUESTED,
  HEADING_WITHDRAWN,
  HEADING_WITHDRAWN_BY_ADMIN,
} from './PanelHeading';
import css from './TransactionPanel.css';
import { TransitActionButtonsMaybe } from './TransitActionButtonsMaybe';
import { getNextCancelTransition, getNextInteractableTransition } from './transitionHelper';
import { PickUpButtonMaybe, pickupTransitions } from './PickupButtonsMaybe';
import {
  DropOffButtonMaybe,
  dropoffTransitions,
  LONG_TERM_STATUS_FINISH,
} from './DropOffButtonsMaybe';
// import { showSmallPrint } from './ShowSmallPrint';
import { CancelButtonsMaybe } from './CancelButtonMaybe';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import {
  txHasFirstReviewedByCustomerLTL,
  txHasFirstReviewedByProviderLTL,
  txIsAfterTripEndLTL,
  txIsBeingDropOffLTL,
  txIsCompletedLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import PartnerInfo from './PartnerInfo';
import ListingMap from './ListingMap';
import { LockUnlockButtonMaybe } from './LockUnlockButtonsMaybe';
import { priceData } from '../../containers/ListingPage/ListingPage.helper';
import { richText } from '../../util/richText';
import LineItemBookingPeriodNew from '../BookingBreakdown/LineItemBookingPeriodNew';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import {
  EVENT_CONVERSION_DRIVER_CONFIRMED_DROPOFF,
  EVENT_CONVERSION_HOST_CONFIRMED_DROPOFF,
} from '../../util/conversions/conversionsConstants';

const { LatLng, UUID } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;
  let otherUser = null;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    otherUser,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isCancelPolicyOpen: false,
      reviewSubmitted: false,
      isStatic: true,
      showCancelModal: false,
      showDisputeModal: false,
      showImgModal: false,
      showImgModalUrl: null,
      reasonDispute: '',
      tripPhotos: [],
      reasonDisputePhotoUrls: [],
      isOpenRentalAgreement: false,
      userCloseModal: false,
      numberOdometer: null,
      isEditTrip: false,
      editWithAdditionalTime: false,
      isEditBookingActive: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleChooseDisputePhoto = this.handleChooseDisputePhoto.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.userCloseModal) {
      if (!this.state.isReviewModalOpen) {
        const { transaction, transactionRole } = nextProps;
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        if (transaction) {
          const isCompleted = txIsCompleted(transaction) || txIsCompletedLTL(transaction);
          const isReviewedByCustomer =
            txHasFirstReviewedByCustomer(transaction) ||
            txHasFirstReviewedByCustomerLTL(transaction);
          const isReviewedByProvider =
            txHasFirstReviewedByProvider(transaction) ||
            txHasFirstReviewedByProviderLTL(transaction);

          if (isCompleted) {
            if (isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          } else {
            if (isReviewedByCustomer && isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isReviewedByProvider && isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          }
        }
      }
    }
  }

  handleOpenStatusModal = idBtn => {
    document.getElementById(idBtn).click();
  };

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    let currentTransaction = ensureTransaction(transaction);
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    if (isFinishLongTerm) {
      currentTransaction = transaction && transaction.currentChildTransaction;
    }
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const events =
      transactionRole === 'provider'
        ? [EVENT_TRIP_HOST_REVIEW_GUEST, EVENT_TRIP_HOST_REVIEW_HOST]
        : [EVENT_TRIP_GUEST_REVIEW_HOST, EVENT_TRIP_GUEST_REVIEW_GUEST];

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        pushGTMTripEvent({
          props: this.props,
          event: events[0],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
          userDiffActionTaker: true,
        });
        pushGTMTripEvent({
          props: this.props,
          event: events[1],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
        });
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      // window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onEditTrip = () => {
    this.setState({
      isEditTrip: !this.state.isEditTrip
    })
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const event =
      transactionRole === 'provider' ? EVENT_TRIP_MESSAGE_SENT_HOST : EVENT_TRIP_MESSAGE_SENT_GUEST;

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        pushGTMTripEvent({
          props: this.props,
          event: event,
          transaction,
          buttonId: TRIP_SEND_MESSAGE_BUTTON_ID,
          existTransaction: true,
        });
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleChooseDisputePhoto(fileUrl) {
    const isExisted = this.state.reasonDisputePhotoUrls.find(url => url === fileUrl);
    if (isExisted) {
      this.setState({
        reasonDisputePhotoUrls: this.state.reasonDisputePhotoUrls.filter(url => url !== fileUrl),
      });
    } else {
      this.setState({
        reasonDisputePhotoUrls: [...this.state.reasonDisputePhotoUrls, fileUrl],
      });
    }
  }

  onPushDisputeEventGTM = () => {
    const { transaction, transactionRole } = this.props;
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_HOST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_GUEST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  onPushPickUpAndDropOffEventGTM = (transaction, transitionName) => {
    let event = [];
    if (transitionName && transitionName.includes('request-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_SENT, EVENT_TRIP_PICKUP_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_CONFIRM, EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST];
    } else if (transitionName && transitionName.includes('request-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_SENT, EVENT_TRIP_DROPOFF_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_CONFIRM, EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST];
    }
    pushGTMTripEvent({
      props: this.props,
      event: event[0],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: event[1],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
      userDiffActionTaker: true,
    });
  };

  onCancelTripGTM = isAccepted => {
    const { transaction, transactionRole } = this.props;
    let events;
    if (isAccepted) {
      events =
        transactionRole === 'provider'
          ? [SERVER_EVENT_TRIP_HOST_CANCEL_GUEST, EVENT_TRIP_HOST_CANCEL_HOST]
          : [SERVER_EVENT_TRIP_GUEST_CANCEL_HOST, EVENT_TRIP_GUEST_CANCEL_GUEST];
      pushGTMTripEvent({
        props: this.props,
        event: events[0],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      pushGTMTripEvent({
        props: this.props,
        event: events[1],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
      });
    } else {
      events = [SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST, EVENT_BOOK_REQUEST_WITHDRAWN_GUEST];
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[0],
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[1],
        existTransaction: true,
      });
    }
  };

  handlePaymentForChildLongTerm = () => {
    const {
      callSetInitialValues,
      onInitializeCardPaymentData,
      onResetCode,
      history,
      transaction,
      childTransaction: {},
    } = this.props;
    const childTransaction = transaction.nextTransaction;
    const listing = get(transaction, 'listing');
    const location = get(listing, 'attributes.publicData.location', {});
    const bookingStart = get(childTransaction, 'booking.attributes.displayStart');
    const bookingEnd = get(childTransaction, 'booking.attributes.displayEnd');
    const transactionId = get(childTransaction, 'id.uuid');
    if (!transactionId) {
      window && window.location.reload();
    }
    const initialValues = {
      parentTransaction: transaction,
      listing,
      bookingData: {
        signupCredits: 0,
        voucherCode: null,
      },
      bookingDates: {
        bookingStart: new Date(bookingStart),
        bookingEnd: new Date(bookingEnd),
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutLongTermPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    onResetCode();
    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutLongTermPage',
        routes,
        { id: listing.id.uuid, transactionId, slug: createSlug(listing.attributes.title) },
        { searchPageParams: location.search }
      )
    );
  };

  getChildTransactionData = (value) => {
    const mapedLineItems = value.lineItems.map(item => {
       return {
         ...item,
         lineTotal: convertMoneyToNumber(item.lineTotal),
         unitPrice: convertMoneyToNumber(item.unitPrice),
       }
    })

    const childTransaction = {
      lineItems: mapedLineItems,
      bookingStart: moment(value.bookingStart).format('YYYY-MM-DD HH:mm:ss'),
      bookingEnd: moment(value.bookingEnd).format('YYYY-MM-DD HH:mm:ss'),
      payinTotal: convertMoneyToNumber(value.payinTotal),
      transactionId: value.transactionId,
      transaction: value.transaction,
    };

    this.setState({
      childTransaction
    })
  }

  getAccurateGeoLocation = (transaction , geolocation) => {
    if (transaction && transaction.attributes &&
      transaction.attributes.metadata &&
      transaction.attributes.metadata["shuStatus1hour"]){
        const shuStatus = transaction.attributes.metadata["shuStatus1hour"]
        if (typeof shuStatus === "object"){
           const { last_known_gps_latitude : gpsLat,
            last_known_gps_longitude : gpsLng } = shuStatus
            if (gpsLat && !isNaN(gpsLat) && gpsLng && !isNaN(gpsLng)){
                return {
                  ...geolocation,
                  lat: gpsLat,
                  lng: gpsLng
                }
            }
        }
      }
      return geolocation;
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptButtonName,
      declineButtonName,
      onTransit,
      transitInProgress,
      transitError,
      keyRulesConfig,
      onSendMessage,
      ownListing,
      nextTransitions,
      transactionWithoutRestore,
      acceptSaleGTM,
      declineSaleGTM,
      onHandlePaymentFuel,
      onFetchTimeSlots,
      monthlyTimeSlots,
      timeSlots,
      checkedCode,
      checkCodeInProgress,
      checkCodeErorr,
      unitType,
      onCheckingVoucher,
      onEstimateBreakdown,
      estimateBreakdownInProgress,
      estimateError,
      estimatedTx,
      fetchTimeSlotsInProgress,
      getListing,
      onRequestToUpdateBooking,
      customerUpdateBookingInProgress,
      onFetchCancelUpdateBooking,
      fuelRulesConfig,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentAuthor = ensureUser(currentListing.author);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    let insuranceType = currentListing.attributes.publicData.insurance;
    const timeZone = getDefaultTimeZoneOnBrowser(currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone);
    const isDelivery = currentListing.attributes.publicData.delivery;
    const isLiveListing = currentListing.id && currentListing.attributes.metadata.live;
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const localTimeZone = getDefaultTimeZoneOnBrowser(currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone);
    const isLongTermListing = listingIsLongTermRental(currentListing);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const bookingConfig = getBookingConfig(currentListing);
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    // const isSearchingLongTerm = useSelector(state => state.SearchPage.isSearchingLongTerm);
    // const queryMaybe = parse(location.search);
    // const shouldShowPricePerMonth = queryMaybe.pub_longTermRental || isSearchingLongTerm;
    const { price = null, title = '' } = currentListing.attributes;
    const listingState = currentListing ? currentListing.attributes.state : null;
    const { longTermRental } = currentListing.attributes.publicData;

    const { formattedPrice, priceTitle } = priceData(
      price,
      intl,
      // longTermRental && shouldShowPricePerMonth,
      true,
      currentListing
    );
    const stateData = this.stateDataFn(
      isCustomer,
      transaction,
      isProvider,
      isCustomerBanned,
      isDelivery
    );
    const currentUserRole = getUserTxRole(currentUser.id, transaction);

    const hasCertificateOfInsurance = !!(
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.certificate
    );

    const rentalAgreementFileLink =
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.rentalAgreementFileLink;

    // console.log({ rentalAgreementFileLink, currentTransaction });

    const bannedUserDisplayName = intl.formatMessage({
      id: 'TransactionPanel.bannedUserDisplayName',
    });
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const numberOdometerLabel = intl.formatMessage({
      id: 'TransactionPanel.numberOdometerLabel',
    });

    const numberOdometerPlaceholder = intl.formatMessage({
      id: 'TransactionPanel.numberOdometerPlaceholder',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
      otherUser,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const modifiedGeoLocation = this.getAccurateGeoLocation(
    transaction,
      geolocation
    )
    const isLocationModified = modifiedGeoLocation !== geolocation

    const listingRegistrationNumber = currentListing.attributes.publicData.license_plate_number;

    const isInstantBooking = currentListing.attributes.publicData.instantBooking;

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const actionButtonClasses = classNames(css.actionButtons);

    const nextInteractableTransitions = getNextInteractableTransition(
      isFinishLongTerm ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const currentChildTransaction = get(
      currentTransaction,
      'attributes.metadata.currentChildTransaction'
    );
    const nextCancelTransition = getNextCancelTransition(
      currentChildTransaction ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextTransit = nextInteractableTransitions.length > 0;
    const haveNextCancelTransit = nextCancelTransition.length > 0;
    const nextTransitIsPickup = pickupTransitions.includes(nextInteractableTransitions[0]);
    const nextTransitIsDropOff = dropoffTransitions.includes(nextInteractableTransitions[0]);
    if(nextTransitIsDropOff){
      const properties = createRawPropertiesForGTM({
        props: this.props,
      });
      if(isProvider){
        createConversionEvents(properties, EVENT_CONVERSION_HOST_CONFIRMED_DROPOFF)
      } else {
        createConversionEvents(properties, EVENT_CONVERSION_DRIVER_CONFIRMED_DROPOFF)
      }
    }
    const validTripPhotos =
      currentTransaction.attributes.protectedData.photoObjects &&
      currentTransaction.attributes.protectedData.photoObjects.length > 0;
    const allTripPhotos = validTripPhotos
      ? currentTransaction.attributes.protectedData.photoObjects
      : [];

    const needInputOdometer =
      get(currentTransaction, 'attributes.protectedData.payForFuel', false) &&
      [...pickupTransitions, ...dropoffTransitions].includes(nextInteractableTransitions[0]);
    const confirmTransitions = [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_CONFIRM_DROP_OFF,
      TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_ADMIN_CONFIRM_DROP_OFF,
      TRANSITION_REQUEST_DROP_OFF_DLGO,
      TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
      TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE
    ];
    const inputOdometerInvalid =
      !confirmTransitions.includes(nextInteractableTransitions[0]) &&
      needInputOdometer &&
      (isEmpty(this.state.numberOdometer) || isNaN(this.state.numberOdometer));

    const transactionForCancel = !isLongTerm
      ? transactionWithoutRestore
      : currentChildTransaction
      ? currentTransaction.currentChildTransaction
      : currentTransaction;
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        currentUser={currentUser}
        onAcceptSale={onAcceptSale}
        onDeclineSale={onDeclineSale}
        transaction={currentTransaction}
        acceptButtonName={acceptButtonName}
        declineButtonName={declineButtonName}
        acceptSaleGTM={acceptSaleGTM}
        declineSaleGTM={declineSaleGTM}
        onRequestToUpdateBooking={onRequestToUpdateBooking}
      />
    );

    const updateBookingButtons = (
      <UpdateBookingButtonsMaybe
        showButtons={stateData.showBookingButtons}
        transaction={currentTransaction}
        onRequestToUpdateBooking={onRequestToUpdateBooking}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
      />
    );

    const transitButtons = (
      <TransitActionButtonsMaybe
        rootClassName={actionButtonClasses}
        canShowButtons={haveNextTransit}
        transaction={currentTransaction}
        transitInProgress={transitInProgress}
        transitError={transitError}
        currentUser={currentUser}
        onTransit={onTransit}
        transitionNames={nextInteractableTransitions}
        handleOpenStatusModal={this.handleOpenStatusModal}
        tripPhotos={this.state.tripPhotos}
        allTripPhotos={allTripPhotos}
        ownListing={ownListing}
        onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
      />
    );

    const lockUnlockButtonMaybe = (
      <LockUnlockButtonMaybe
        rootClassName={actionButtonClasses}
        canShowButtons={haveNextTransit}
        transaction={currentTransaction}
        transitInProgress={transitInProgress}
        transitError={transitError}
        onTransit={onTransit}
        currentUser={currentUser}
        transitionNames={nextInteractableTransitions}
        handleOpenStatusModal={this.handleOpenStatusModal}
        tripPhotos={this.state.tripPhotos}
        allTripPhotos={allTripPhotos}
        ownListing={ownListing}
        onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
      />
    );

    const pickUpButtonMaybe = (
      <PickUpButtonMaybe
        currentUserRole={currentUserRole}
        rootClassName={actionButtonClasses}
        canShowButtons={haveNextTransit}
        transaction={currentTransaction}
        transitInProgress={transitInProgress}
        transitError={transitError}
        onTransit={onTransit}
        currentUser={currentUser}
        transitionNames={nextInteractableTransitions}
        handleOpenStatusModal={this.handleOpenStatusModal}
        tripPhotos={this.state.tripPhotos}
        allTripPhotos={allTripPhotos}
        onOpenDisputeModal={() => this.setState({ showDisputeModal: true })}
        ownListing={ownListing}
        onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
        inputOdometerInvalid={inputOdometerInvalid}
        inputNumberOdometer={this.state.numberOdometer}
      />
    );

    const dropOffButtonMaybe = (
      <DropOffButtonMaybe
        currentUser={currentUser}
        currentUserRole={currentUserRole}
        rootClassName={actionButtonClasses}
        canShowButtons={haveNextTransit}
        transaction={currentTransaction}
        transitInProgress={transitInProgress}
        transitError={transitError}
        onTransit={onTransit}
        transitionNames={nextInteractableTransitions}
        handleOpenStatusModal={this.handleOpenStatusModal}
        tripPhotos={this.state.tripPhotos}
        allTripPhotos={allTripPhotos}
        onOpenDisputeModal={() => this.setState({ showDisputeModal: true })}
        ownListing={ownListing}
        onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
        inputOdometerInvalid={inputOdometerInvalid}
        inputNumberOdometer={this.state.numberOdometer}
        isLongTerm={isLongTerm}
      />
    );

    const dropOffOdometer = get(currentTransaction, 'attributes.protectedData.dropOffOdometer', 0);
    const pickUpOdometer = get(currentTransaction, 'attributes.protectedData.pickUpOdometer', 0);
    const isNextConfirmDropOff = nextInteractableTransitions.includes(TRANSITION_CONFIRM_DROP_OFF);
    const isNextConfirmPickUp = nextInteractableTransitions.includes(
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE
    );
    const odometerValue = isNextConfirmDropOff
      ? dropOffOdometer
      : isNextConfirmPickUp
      ? pickUpOdometer
      : undefined;
    const inputOdometer = (
      <div>
        <label className={css.detailCardTitle} htmlFor="numberOdometer">
          {numberOdometerLabel}
        </label>
        <input
          id={'numberOdometer'}
          name="numberOdometer"
          disabled={isNextConfirmDropOff || isNextConfirmPickUp}
          className={css.numberOdometer}
          type="text"
          placeholder={numberOdometerPlaceholder}
          value={odometerValue}
          onChange={event => this.setState({ numberOdometer: event.target.value })}
        />
      </div>
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const isVerified =
      isProvider ||
      (!!currentUser &&
        !!currentUser.id &&
        currentUser.identityStatus &&
        currentUser.identityStatus.guestVerified);

    const isEnquiry = txIsEnquired(currentTransaction);
    const isShowPartnerInfo =
      !txIsRequested(transaction) &&
      !txIsDeclined(transaction) &&
      !txIsExpired(transaction) &&
      !txIsRequestedLTF(transaction) &&
      !txIsDeclinedLTF(transaction) &&
      !txIsExpiredLTF(transaction);
    const isShowCancelPanel = !txIsCompleted(transaction) && !txIsReviewed(transaction);

    const isCancelTransaction = get(currentTransaction, 'attributes.metadata.cancelData');
    const isShowUploadModal =
      (((txIWaitingRequestPickup(currentTransaction) ||
        txIWaitingRequestPickupLTF(currentTransaction)) &&
        isCustomer) ||
        ((txIsBeingPickedUp(currentTransaction) || txIsBeingPickedUpLTF(currentTransaction)) &&
          isProvider) ||
        ((txIWaitingRequestDropoff(currentTransaction) ||
          txIWaitingRequestDropoffLTL(
            currentTransaction && currentTransaction.currentChildTransaction
          )) &&
          isCustomer) ||
        txIsBeingDropOff(currentTransaction) ||
        (txIsBeingDropOffLTL(currentTransaction && currentTransaction.currentChildTransaction) &&
          isProvider)) &&
      haveNextTransit;

    const showSectionUploadCar =
      isShowPartnerInfo &&
      ((txIWaitingRequestPickup(currentTransaction) && isCustomer) ||
        txIsBeingPickedUp(currentTransaction) ||
        txIsAfterConfirmPickUp(currentTransaction) ||
        txHasBeenDelivered(currentTransaction) ||
        (txIWaitingRequestPickupLTF(currentTransaction) && isCustomer) ||
        txIsBeingPickedUpLTF(currentTransaction) ||
        txIWaitingRequestDropoffLTL(
          currentTransaction && currentTransaction.currentChildTransaction
        ) ||
        txIsAfterTripEndLTL(currentTransaction && currentTransaction.currentChildTransaction));
    const normalBreakdown = (
      <BreakdownMaybe
        currentUser={currentUser}
        className={css.breakdownContainer}
        transaction={currentTransaction}
        transactionRole={transactionRole}
        timeZone={timeZone}
      />
    );
    const nextPaymentAt = get(
      currentTransaction,
      'nextTransaction.booking.attributes.displayStart'
    );
    const showNextPaymentAt = nextPaymentAt && !isFinishLongTerm;
    const dateCanMakeNextPayment = moment(nextPaymentAt).subtract(7, 'days');
    const longTermBreakdown = (
      <div>
        <BreakdownLongTermMaybe
          currentUser={currentUser}
          className={css.breakdownContainer}
          transaction={currentTransaction}
          transactionRole={transactionRole}
          timeZone={timeZone}
        />
        {showNextPaymentAt && isCustomer && !isCancelTransaction && (
          <div className={css.dateForNextPayment}>
            <FormattedMessage id="TransactionPanel.dateForNextPayment" />
            {dateCanMakeNextPayment.format('DD.MM.YYYY')}
          </div>
        )}
      </div>
    );

    const normalBreakdownMobile = (
      <BreakdownMaybe
        currentUser={currentUser}
        transaction={currentTransaction}
        transactionRole={transactionRole}
        transactionWithoutRestore={transactionWithoutRestore}
        shouldShowMastercardPromoLineItem
        timeZone={timeZone}
      />
    );

    const longTermBreakdownMobile = (
      <div>
        <BreakdownLongTermMaybe
          currentUser={currentUser}
          transaction={currentTransaction}
          transactionRole={transactionRole}
          timeZone={timeZone}
          transactionWithoutRestore={transactionWithoutRestore}
        />
        {showNextPaymentAt && isCustomer && !isCancelTransaction && (
          <div className={css.dateForNextPayment}>
            <FormattedMessage id="TransactionPanel.dateForNextPayment" />
            {dateCanMakeNextPayment.format('DD.MM.YYYY')}
          </div>
        )}
      </div>
    );

    const breakdown = isLongTerm ? longTermBreakdown : normalBreakdown;
    const breakdownMobile = isLongTerm ? longTermBreakdownMobile : normalBreakdownMobile;

    const haveNextPayment = !isFinishLongTerm && moment() > dateCanMakeNextPayment && isCustomer;
    // const isCancelTransaction = isCancelledTransactionLTF(currentTransaction) ||
    // txIsCancelledTransactionLTL(currentTransaction.currentChildTransaction) || txIsCancelledTransactionLTM(currentTransaction.currentChildTransaction);
    const isShowMakeNextPaymentButton = isLongTerm && haveNextPayment && !isCancelTransaction;
    const paymentButton = (
      <div className={css.actionButtons}>
        <PrimaryButton
          inProgress={false}
          disabled={false}
          onClick={this.handlePaymentForChildLongTerm}
        >
          <FormattedMessage id="TransactionPanel.LongTerm.makeNextMonthPayment" />
        </PrimaryButton>
      </div>
    );

    const customerPostalCode = currentTransaction.attributes.protectedData && currentTransaction.attributes.protectedData.customerPostalCode ? currentTransaction.attributes.protectedData.customerPostalCode : null;
    const customerBlockNo = currentTransaction.attributes.protectedData && currentTransaction.attributes.protectedData.customerBlockNo ? currentTransaction.attributes.protectedData.customerBlockNo : null;
    const customerCity = currentTransaction.attributes.protectedData && currentTransaction.attributes.protectedData.customerCity ? currentTransaction.attributes.protectedData.customerCity : null;
    const customerLocation = currentTransaction.attributes.protectedData && currentTransaction.attributes.protectedData.customerLocation ? currentTransaction.attributes.protectedData.customerLocation : null;
    const deliveryLineItem = currentTransaction.attributes && currentTransaction.attributes.lineItems ? currentTransaction.attributes.lineItems.some(lineItem => lineItem.code === 'line-item/delivery') : false;

    const deliveryAddressSection = (<div className={css.addonsHolder}>
      <h2 className={css.addonsTitle}>
        <FormattedMessage id="TransactionPanel.addonsTitle" />
      </h2>
      <p className={css.addonsSubTitle}>
        <FormattedMessage id="TransactionPanel.addonsText" values={{hostName: authorDisplayName, customerName: customerDisplayName }} />
      </p>
      <p className={css.addressMaybe}>
        {customerBlockNo && <span>#{customerBlockNo},</span>}
        {customerLocation && customerLocation.selectedPlace && <span> {customerLocation.selectedPlace.address},</span>}
        {customerCity && <span> {customerCity},</span>}
        {customerPostalCode && <span> {customerPostalCode}</span>}
      </p>
    </div>)

    const richTitle = (
      <span>
      {richText(title, {
        longWordMinLength: 16,
        longWordClass: css.longWord,
      })}
    </span>
    );

    const bookingTitle = (
      <FormattedMessage id="TransactionPanel.bookingTitle" values={{ title: richTitle }} />
    );

    const bookingUpdateSubTitle = (<div className={css.updateSubTitle}>
      <FormattedMessage id="TransactionPanel.bookingSubTitle" />
    </div>)

    const onReadInsurance = () => {
      initiateEventFromListing({
        props: this.props,
        listing: currentListing,
        event: EVENT_SEARCH_READ_INSURANCE,
        isHost: checkOwnListing(currentUser, currentListing),
      });
    };

    const checkValidateBooking = () => {
      const emailVerificationNeeded = !!currentUser.id && !currentUser.attributes.emailVerified;

      const mobileInputNeeded = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
        !currentUser.attributes.profile.protectedData.phoneNumber
          ? true
          : false;
      const mobileUnverified = !currentUser.id
        ? false
        : !!currentUser.attributes.profile.protectedData &&
        !currentUser.attributes.profile.protectedData.phoneNumberVerified
          ? true
          : false;
      const mobileVerificationNeeded = mobileUnverified; //Show that mobile need verified or not

      // if (emailVerificationNeeded || mobileVerificationNeeded || mobileInputNeeded) {
      //   this.props.onOpenMissingInfomationModal(true);
      //   return false;
      // }
      return true;
    };

    const additionalId = currentTransaction.attributes.protectedData && currentTransaction.attributes.protectedData.additionalTimeTransactionId;
    const hasAdditionalTransaction = !!additionalId;
    const { editWithAdditionalTime, isEditTrip } = this.state;


    const renderBookingPanelForUpdate = () => {

      const onSubmitUpdateBooking = values => {
        const {
          history,
          callSetInitialValues,
          onInitializeCardPaymentData,
          onResetCode,
          location,
        } = this.props;

        const { bookingStartTime, bookingEndTime, signupCredits, voucherCode, payForFuel } = values;
        const bookingStart = timestampToDate(parseInt(bookingStartTime));
        const bookingEnd = timestampToDate(parseInt(bookingEndTime));

        const currentTransition =
          txIsAcceptedRefundable(currentTransaction) ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE :
          txIsAcceptedNonRefundable(currentTransaction) ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE :
          txIsAcceptedAfterOneHour(currentTransaction) ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR : TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE;

        const updateBookingParams = {
          transition: currentTransition,
          transactionId: currentTransaction.id,
          listing: currentTransaction.listing,
          bookingStart,
          bookingEnd,
          protectedData: {
            childTransaction: this.state.childTransaction
          }
        }

        const routes = routeConfiguration();
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
        const listing = currentTransaction.listing;
        const initialValues = {
          listing,
          bookingData: {
            signupCredits,
            voucherCode,
            transactionId: currentTransaction.id,
            payForFuel: false,
            isPaidAmount: true,
            // paidAmount: -(currentTransaction.attributes.payinTotal.amount),
            updateBookingParams: updateBookingParams
          },
          bookingDates: {
            bookingStart: timestampToDate(bookingStartTime),
            bookingEnd: timestampToDate(bookingEndTime),
          },
          confirmPaymentError: null,

        };

        callSetInitialValues(setInitialValues, initialValues);

        // Clear previous Stripe errors from store if there is any
        onInitializeCardPaymentData();

        onResetCode();

        // Redirect to CheckoutPage
        history.push(
          createResourceLocatorString(
            'CheckoutPage',
            routes,
            { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
            { searchPageParams: location.search },
            {},
          ),
          {updateBooking: true}

        );
      };

      const filtMonthlySlots = slots => {
        if (!hasAdditionalTransaction || !editWithAdditionalTime) {
          return slots;
        }
        const seats = currentListing.attributes.publicData.seats;

        const _slots = {...slots};
        for (let month in _slots) {
          _slots[month] = {
            ..._slots[month],
            timeSlots: _slots[month].timeSlots && _slots[month].timeSlots.filter(slot => slot.attributes.seats == seats)} || null;
        }

        return _slots;
      };

      const displayStart = currentTransaction.booking.attributes.displayStart;
      const displayEnd = currentTransaction.booking.attributes.displayEnd;

      return (
        <BookingPanel
          timeSlots={timeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          checkedCode={checkedCode}
          checkCodeInProgress={checkCodeInProgress}
          checkCodeErorr={checkCodeErorr}
          onCheckingVoucher={onCheckingVoucher}
          currentUser={currentUser}
          className={css.bookingPanel}
          listing={currentListing}
          isOwnListing={isOwnListing}
          unitType={unitType}
          onSubmit={onSubmitUpdateBooking}
          monthlyTimeSlots={filtMonthlySlots(monthlyTimeSlots)}
          title={bookingTitle}
          updateSubTitle={bookingUpdateSubTitle}
          authorDisplayName={authorDisplayName}
          onResetCode={() => {}}
          onManageDisableScrolling={onManageDisableScrolling}
          checkValidateBooking={checkValidateBooking}
          calculateMobileHeight={() => {}}
          initialDate={{
            startDate: displayStart,
            endDate: displayEnd,
            pickupTime: displayStart,
            dropoffTime: displayEnd,
          }}
          isLiveListing={isLiveListing}
          onReadInsurance={onReadInsurance}
          requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
          onEstimateBreakdown={onEstimateBreakdown}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          // onOpenRentalAgreement={() => setIsOpenRentalAgreement(true)}
          bookingConfig={bookingConfig}
          estimateError={estimateError}
          fetchTimeSlotsInProgress={fetchTimeSlotsInProgress}
          localTimeZone={localTimeZone}
          isLongTerm={isLongTermListing}
          isLongTermBooking={isLongTerm}
          // shouldShowPricePerMonth={isLongTermListing && shouldShowPricePerMonth}
          formattedPrice={formattedPrice}
          priceTitle={priceTitle}
          longTermRental={longTermRental}
          transaction={currentTransaction}
          updateBooking={true}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          getChildTransactionData={this.getChildTransactionData}
          isEditTrip={isEditTrip}
        />

      )
    };

    const fuelKey = currentListing.attributes.publicData.fuelType
    let fuelType = fuelRulesConfig.find((fuel) => {
      return fuel.key === fuelKey
    })
    fuelType = fuelType ? fuelType.label : fuelKey

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {!isEditTrip && (<DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              user={otherUser}
              isCustomer={isCustomer}
              showAvatar={true}
            />)}
            {isEditTrip && (<div className={css.titleContainer}>
              <div className={css.bookingStatusText} onClick={this.onEditTrip}>
                <span>&#8249; {title}</span>
              </div>
              <h2 className={css.listingTitle}>Edit Trip</h2>
            </div>)}
            {!isEditTrip && <PartnerInfo
              currentUserRole={currentUserRole}
              transaction={transaction}
              otherUser={otherUser}
            />}
            {isEditTrip && renderBookingPanelForUpdate()}

            {!isVerified && !isEnquiry && !isEditTrip ? (
              <TransactionIdentityVerification currentUser={currentUser} />
            ) : null}

            {(isVerified || isEnquiry) && !isEditTrip && (
              <div>
                {!isEditTrip && (<PanelHeading
                  panelHeadingState={stateData.headingState}
                  transactionRole={transactionRole}
                  providerName={authorDisplayName}
                  customerName={customerDisplayName}
                  isCustomerBanned={isCustomerBanned}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingTitle={listingTitle}
                  listingDeleted={listingDeleted}
                />)}

                <div className={css.bookingDetailsMobile}>
                  <div className={css.addressMobileWrapper}>
                    <h2 className={css.detailCardTitle}>
                      <FormattedMessage id="TransactionPanel.tripCode" />
                    </h2>
                    <p className={css.tripCode}>{transaction.id.uuid}</p>
                    <h2 className={css.detailCardTitle}>
                      <FormattedMessage id="TransactionPanel.pickupLocationTitle" />
                    </h2>
                    <AddressLinkMaybe
                      rootClassName={css.addressMobile}
                      location={location}
                      geolocation={modifiedGeoLocation}
                      showAddress={stateData.showAddress}
                      customAddress={isLocationModified ?
                      <FormattedMessage id="TripPanel.carLocation" /> : null
                      }
                    />
                  </div>
                  <ListingMap geolocation={modifiedGeoLocation} listing={currentListing} showAddress={stateData.showAddress} />
                  {breakdownMobile}
                  {/* {!txIsCanceledByHost(transaction) && (
                    <div>
                      {smallPrint}
                    </div>
                  )} */}
                  {!isEditTrip && !txIsCanceledByHost(transaction) && (
                    <InsurancePanelNew
                      listing={currentListing}
                      showInsurance={true}
                      className={css.insuranceWrapper}
                      hasBookingData={!!currentTransaction.id}
                      insuranceType={insuranceType}
                      onManageDisableScrolling={onManageDisableScrolling}
                    />
                  )}
                  <div className={css.rentalAgreementLinkMobile}>
                    {!isEditTrip && rentalAgreementFileLink ? (
                      <span
                        onClick={() => window && window.open(rentalAgreementFileLink, '_blank')}
                      >
                        <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                      </span>
                    ) : (
                      <span onClick={() => this.setState({ isOpenRentalAgreement: true })}>
                        <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                      </span>
                    )}
                  </div>
                  {!isEditTrip && hasCertificateOfInsurance && (
                    <div className={css.rentalAgreementLinkMobile}>
                      <span
                        onClick={() =>
                          window &&
                          window.open(
                            transaction.attributes.protectedData.certificate.directLink,
                            '_blank'
                          )
                        }
                      >
                        <FormattedMessage id="BookingDatesForm.certificateOfInsuranceLink" />
                      </span>
                    </div>
                  )}
                </div>

                {savePaymentMethodFailed ? (
                  <p className={css.genericError}>
                    <FormattedMessage
                      id="TransactionPanel.savePaymentMethodFailed"
                      values={{ paymentMethodsPageLink }}
                    />
                  </p>
                ) : null}

                <FeedSection
                  onOpenShowImgModal={url =>
                    this.setState({ showImgModal: true, showImgModalUrl: url })
                  }
                  rootClassName={css.feedContainer}
                  currentTransaction={currentTransaction}
                  currentUser={currentUser}
                  fetchMessagesError={fetchMessagesError}
                  fetchMessagesInProgress={fetchMessagesInProgress}
                  initialMessageFailed={initialMessageFailed}
                  messages={messages}
                  oldestMessagePageFetched={oldestMessagePageFetched}
                  onOpenReviewModal={this.onOpenReviewModal}
                  onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                  totalMessagePages={totalMessagePages}
                  isFinishLongTerm={isFinishLongTerm}
                />
                <div id="headMessageForm" className={css.headMessageForm} />
                {showSendMessageForm ? (
                  <SendMessageForm
                    currentTransaction={currentTransaction}
                    currentUser={currentUser}
                    messages={messages}
                    formId={this.sendMessageFormName}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={sendMessagePlaceholder}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={this.onSendMessageFormFocus}
                    onBlur={this.onSendMessageFormBlur}
                    onSubmit={this.onMessageSubmit}
                    submitButtonId={TRIP_SEND_MESSAGE_BUTTON_ID}
                  />
                ) : (
                  <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
                )}
                {stateData.showSaleButtons ? (
                  <div className={css.mobileActionButtons}>{saleButtons}</div>
                ) : null}

                {/*{stateData.showUpdateBookingButtons ? (*/}
                {/*  <div className={css.mobileActionButtons}>{updateBookingButtons}</div>*/}
                {/*) : null}*/}

                {haveNextTransit && !nextTransitIsPickup && !nextTransitIsDropOff ? (
                  <div className={css.mobileActionButtons}>{transitButtons}</div>
                ) : null}

                {isShowMakeNextPaymentButton && (
                  <div className={css.mobileActionButtons}>{paymentButton}</div>
                )}
                <div className={css.mobileActionButtonsPickUp}>{dropOffButtonMaybe}</div>
                <div className={css.mobileActionButtons}>{pickUpButtonMaybe}</div>
                <div className={css.mobileActionButtons}>{lockUnlockButtonMaybe}</div>
              </div>
            )}

            <div className={css.cancelPanel}>
              {!isEditTrip && ((txIsRequested(transaction) && deliveryLineItem ) || (txIsAccepted(transaction) && deliveryLineItem)) ? deliveryAddressSection : null}
              {!isEditTrip && (<SectionRulesMaybe
                className={css.keyRulesItem}
                title={'TransactionPanel.keyRulesTitle'}
                subTitle={isProvider ? null : 'TransactionPanel.keyRulesSubTitle'}
                titleClassName={css.keyRulesTitle}
                keyRules={keyRulesConfig}
                selectedOptions={currentListing.attributes.publicData.keyRules}
              />)}
              {!isEditTrip && !txIsRequested(transaction) && (
                <SectionRulesMaybe
                  title={'TransactionPanel.typeOfFuel'}
                  titleClassName={css.keyRulesTitle}
                  className={classNames(css.keyRuleText, css.keyRulesItem)}
                  keyRule={fuelType}
                />
              )}
              {!isEditTrip && needInputOdometer && inputOdometer}
              {!isEditTrip && showSectionUploadCar && (
                <SectionUploadCarStatus
                  intl={intl}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isCustomer={isCustomer}
                  isShowUpload={isShowUploadModal}
                  currentUser={currentUser}
                  isPickUp={
                    txIWaitingRequestPickup(currentTransaction) ||
                    txIsBeingPickedUp(currentTransaction) ||
                    txIWaitingRequestPickupLTF(currentTransaction) ||
                    txIsBeingPickedUpLTF(currentTransaction)
                  }
                  setTripPhotos={data => {
                    this.setState({ tripPhotos: [...data] });
                  }}
                  tripPhotos={this.state.tripPhotos}
                  existedPhotos={allTripPhotos}
                  inputOdometerInvalid={inputOdometerInvalid}
                />
              )}
            </div>
          </div>

          <div className={css.asideDesktop}>
            {isEditTrip && <div className={css.detailsContainerTop}>
              <div className={css.detailsAspectWrapper}>
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  alt={listingTitle}
                  image={firstImage}
                  variants={['landscape-crop', 'landscape-crop2x']}
                />
              </div>
              <div className={css.detailsHeadings}>
                <h2 className={css.detailsTitle}>{listingTitle}</h2>
                <LineItemBookingPeriodNew
                  booking={currentTransaction.booking}
                  unitType={unitType}
                  timezone={timeZone}
                  transaction={currentTransaction}
                />
              </div>
            </div>}
            {isEditTrip && (<SmallPartnerInfo
              currentUserRole={currentUserRole}
              transaction={transaction}
              otherUser={otherUser}
              show={true}
              isMobileLayout={false}
            />)}
            {!isEditTrip && <div className={css.detailCard}>
              {!isEditTrip && (<DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                showAvatar={false}
              />)}

              {isEnquiry ? <div className={css.marginTopBox}/> : null}

              {isCustomer && (<div className={css.editTripHolder}>
                <h2 onClick={this.onEditTrip} className={css.detailCardTitle}>
                  Edit trip
                </h2>
              </div>)}

              {!isEnquiry && !isEditTrip ? (
                <div>
                  <DetailCardHeadingsMaybe
                    showDetailCardHeadings={stateData.showDetailCardHeadings}
                    listingTitle={listingTitle}
                    location={location}
                    geolocation={modifiedGeoLocation}
                    showAddress={stateData.showAddress}
                    transaction={currentTransaction}
                    isDelivery={isDelivery}
                    deliveryLineItem={deliveryLineItem}
                  />
                  {!deliveryLineItem &&
                  <ListingMap geolocation={modifiedGeoLocation} listing={currentListing} className={css.desktopInsurance}/>}

                  {breakdown}
                </div>
              ) : null}

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {!isEditTrip && (<div>{updateBookingButtons}</div>)}
              {haveNextTransit && !nextTransitIsPickup && !nextTransitIsDropOff ? (
                <div className={css.desktopActionButtons}>{transitButtons}</div>
              ) : null}

              {isShowMakeNextPaymentButton && (
                <div className={css.desktopActionButtons}>{paymentButton}</div>
              )}
              <div className={css.desktopActionButtons}>{dropOffButtonMaybe}</div>

              <div className={css.desktopActionButtons}>{pickUpButtonMaybe}</div>
              <div className={css.desktopActionButtons}>{lockUnlockButtonMaybe}</div>

              {!isEditTrip && !txIsCanceledByHost(transaction) && (
                <InsurancePanelNew
                  showInsurance={true}
                  listing={currentListing}
                  className={classNames(css.insuranceWrapper, css.desktopInsurance)}
                  hasBookingData={!!currentTransaction.id}
                  insuranceType={insuranceType}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              )}
              {!isEditTrip && (<div className={css.linksWrapper}>
                <div
                  className={classNames(css.rentalAgreementLink, {
                    [css.rentalAgreementLinkCenter]: hasCertificateOfInsurance,
                  })}
                >
                  {rentalAgreementFileLink ? (
                    <span onClick={() => window && window.open(rentalAgreementFileLink, '_blank')}>
                      <FormattedMessage id="BookingDatesForm.rentalAgreementLink"/>
                    </span>
                  ) : (
                    <span onClick={() => this.setState({isOpenRentalAgreement: true})}>
                      <FormattedMessage id="BookingDatesForm.rentalAgreementLink"/>
                    </span>
                  )}
                </div>

                {!isEditTrip && hasCertificateOfInsurance && (
                  <div
                    className={classNames(css.rentalAgreementLink, {
                      [css.rentalAgreementLinkCenter]: hasCertificateOfInsurance,
                    })}
                  >
                    <span
                      onClick={() =>
                        window &&
                        window.open(
                          transaction.attributes.protectedData.certificate.directLink,
                          '_blank'
                        )
                      }
                    >
                      <FormattedMessage id="BookingDatesForm.certificateOfInsuranceLink"/>
                    </span>
                  </div>
                )}
              </div>)}
              {!isEditTrip && isShowCancelPanel && (!txIsRequested(transaction) || haveNextCancelTransit) ? (
                <CancelButtonsMaybe
                  rootClassName={actionButtonClasses}
                  canShowButtons={haveNextCancelTransit}
                  transaction={currentTransaction}
                  transitInProgress={transitInProgress}
                  transitError={transitError}
                  onTransit={onTransit}
                  transitionNames={nextCancelTransition}
                  onOpenCancelModal={() => this.setState({showCancelModal: true})}
                  openCancelPolicyModal={() => this.setState({isCancelPolicyOpen: true})}
                />
              ) : null}
            </div>}
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          buttonId={TRIP_PUBLISH_REVIEW_BUTTON_ID}
        />
        <ModalCancel
          rootClassName={actionButtonClasses}
          canShowButtons={haveNextCancelTransit}
          transaction={transactionForCancel}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={nextCancelTransition}
          openCancelPolicyModal={() => this.setState({ isCancelPolicyOpen: true })}
          isCustomer={isCustomer}
          isProvider={isProvider}
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          showCancelModal={this.state.showCancelModal}
          onClose={() => this.setState({ showCancelModal: false })}
          isInstantBooking={isInstantBooking}
          onCancelTripGTM={this.onCancelTripGTM}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isAccepted={txIsAccepted(currentTransaction)}
          isLongTerm={isLongTerm}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
        />
        <ModalCancelPolicy
          containerClassName={css.missingInformationModal}
          onManageDisableScrolling={onManageDisableScrolling}
          transactionRole={transactionRole}
          showPolicyModal={this.state.isCancelPolicyOpen}
          onClose={() => this.setState({ isCancelPolicyOpen: false })}
          isLongTerm={isLongTerm}
        />
        <ModalReasonDispute
          isOpen={this.state.showDisputeModal}
          onClose={() => this.setState({ showDisputeModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          transaction={currentTransaction}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          onSendMessage={onSendMessage}
          reasonDispute={this.state.reasonDispute}
          reasonDisputePhotoUrls={this.state.reasonDisputePhotoUrls}
          onChoosePhoto={fileUrl => this.handleChooseDisputePhoto(fileUrl)}
          tripPhotos={[...allTripPhotos, ...(isShowUploadModal ? this.state.tripPhotos : [])]}
          onReasonDisputeChange={value => this.setState({ reasonDispute: value })}
          onPushDisputeEventGTM={this.onPushDisputeEventGTM}
          submitButtonId={TRIP_SUBMIT_DISPUTE_BUTTON_ID}
        />
        <ShowImgModal
          isOpen={this.state.showImgModal}
          onClose={() => this.setState({ showImgModal: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          url={this.state.showImgModalUrl}
        />
        <Modal
          id="rentalAgreementModal"
          isOpen={this.state.isOpenRentalAgreement}
          onClose={() => this.setState({ isOpenRentalAgreement: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <RentalAgreement />
        </Modal>
      </div>
    );
  }

  stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned, isDelivery) {
    const lastTransition = transaction.attributes.lastTransition;
    if (txIsEnquired(transaction)) {
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: false,
      };
    } else if (txIsPaymentPending(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(transaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsWithdrawnByAdmin(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN_BY_ADMIN,
        showDetailCardHeadings: true,
      };
    } else if (txIsWithdrawn(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(transaction) || txIsRequestedLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
      };
    } else if (txIsAccepted(transaction) && (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP || lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)) {
      return {
        headingState: HEADING_DECLINED_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction) && (lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP || lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP)) {
      return {
        headingState: HEADING_ACCEPTED_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAwaitUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider
      };
    } else if (txIsAwaitUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider
      };
    }  else if (txIsDeclined(transaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsCanceled(transaction)) {
      return {
        headingState: HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txHasBeenDelivered(transaction)) {
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else {
      return { headingState: 'unknown' };
    }
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitError: null,
  keyRulesConfig: config.custom.keyRules,
  fuelRulesConfig: config.custom.typeOfFuel,
  nextTransitions: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  //transit props
  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape,

  keyRulesConfig: PropTypes.array,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
